const locale = {
	TITLE: 'Clients',
	NOCLIENT: 'Aucun client enregistré',
	ADDCLIENT: 'Ajouter un client',
	ADDUSER: 'Ajouter un utilisateur',
	NEWCLIENT: "Création d'un client",
	PAUSECLIENT: 'Mettre en pause',
	ACTIVECLIENT: 'Activer le client',
	DELETECLIENT: 'Supprimer le client',
	EDITCLIENT: 'Modifier le client',
	PAUSEUSER: 'Mettre en pause',
	ACTIVEUSER: "Activer l'utilisateur",
	DELETEUSER: "Supprimer l'utilisateur",
	EDITUSER: "Modifier l'utilisateur",
	USERS: '#Utilisateurs',
	SAVEUSER: 'Valider',
	TRACKERS: 'Trackers',
	VOIRPLUS: 'Voir plus',
	SEARCHHEADER: 'Rechercher',
	CATEGORY: 'Catégorie',
	CANCEL: 'Annuler',
	AGREE: 'Valider',
	PAUSECLIENTTITLE: 'Êtes-vous sûr de vouloir mettre en pause le client ',
	ACTIVECLIENTTITLE: 'Êtes-vous sûr de vouloir activer le client ',
	DELETECLIENTTITLE: 'Êtes-vous sûr de vouloir supprimer le client',
	PAUSECLIENTDIALOG:
		"Cette action entrainera la mise en pause du client. Ce dernier n'aura plus accès ni à www.stracker360.com,  ni à ses notifications. ",
	ACTIVECLIENTDIALOG: 'Souhaitez-vous réactiver le compte de ce client  ?',
	DELETECLIENTDIALOG:
		"Cette action entrainera la supression définitive du compte client. L'ensemble de ses utilisateurs n'auront plus accès ni à www.stracker360.com, ni à leurs notifications. ",
	PAUSEUSERTITLE: "Êtes-vous sûr de vouloir mettre en pause l'utilisateur",
	ACTIVEUSERTITLE: "Êtes-vous sûr de vouloir activer l'utilisateur",
	DELETEUSERTITLE: "Êtes-vous sûr de vouloir supprimer l'utilisateur",
	PAUSEUSERDIALOG:
		"Cette action entrainera la suspension du compte client. L'ensemble de ses utilisateurs n'auront plus accès ni à www.stracker360.com, ni à leurs  notifications, et ce jusqu'à la réactivation du compte. ",
	ACTIVEUSERDIALOG:
		'Cette action entrainera la réactivation du compte utilisateur. Ce dernier aura de nouveau accès à www.stracker360.com, et ses notifications. ',
	DELETEUSERDIALOG:
		"Cette action entrainera la supression définitive du compte utilisateur. Ce dernier n'aura plus accès ni à www.stracker360.com,  ni à ses notifications. ",
	ENDSCROLL: 'Dernier atteint',
	LOADING: 'Chargement',
	ADDNEWFILTER: 'Filtrer',
	ORDERBY: 'Ranger par',
	NEWUSER: "Création d'un utilisateur",
	ADDUSERS: 'Ajouter un ou des utilisateurs',
	CUSTOMERNAMEAPPEAR: 'Le nom apparaîtra sur le tableau de bord',
	SOCIETE: 'Nom de la société',
	RESPONSABLE: 'Responsable de la société',
	LASTNAME: 'Nom',
	FIRSTNAME: 'Prénom',
	EMAIL: 'Email',
	ACTIVITY: "Type d'activité",
	USER: 'Utilisateur',
	SOCIETY_NAME: 'Nom de la société',
	LOGO: 'Logo',
	SUBSCRIPTION: 'Abonnement',
	YES: 'Oui',
	NO: 'Non',
	SELECT_URL: "Rentrez l'url du logo",
	PREVIEW: 'Prévisualisation',
	NEXT: 'Suivant'
};

export default locale;
