import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

function ThermometerIcon({ marginTop }) {
	const theme = useTheme();
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ marginTop: marginTop }}
		>
			<g clipPath="url(#clip0_10059_3382)">
				<path
					d="M10.5 11.07V2.625C10.5 2.12772 10.3025 1.65081 9.95083 1.29917C9.59919 0.947544 9.12228 0.75 8.625 0.75C8.12772 0.75 7.65081 0.947544 7.29917 1.29917C6.94754 1.65081 6.75 2.12772 6.75 2.625V11.07C6.14796 11.4723 5.69124 12.0575 5.4473 12.7392C5.20337 13.4209 5.18514 14.163 5.39532 14.8559C5.6055 15.5488 6.03293 16.1557 6.6145 16.5871C7.19607 17.0184 7.90094 17.2512 8.625 17.2512C9.34906 17.2512 10.0539 17.0184 10.6355 16.5871C11.2171 16.1557 11.6445 15.5488 11.8547 14.8559C12.0649 14.163 12.0466 13.4209 11.8027 12.7392C11.5588 12.0575 11.102 11.4723 10.5 11.07Z"
					stroke={theme.palette.stracker.contrastTextDark}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10059_3382">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default ThermometerIcon;
