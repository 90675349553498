import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import axios from 'axios';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import { ButtonStyled } from '../StyledComponents/ButtonStyled';

const PingMarker = ({ children }) => <div>{children}</div>;

const DialogPingMap = React.memo(
	({
		openDialogPingMap,
		handleCloseDialogMapPing,
		setGlobalCode,
		globalCode,
		currentDialogPing,
		latState,
		lngState,
		getUpdateAddress,
		id,
		getUpdateAddressTransit,
		isMobileDevice,
		type,
		t,
		geofenceTransport,
		dataCreatePresetAlert,
		page
	}) => {
		let transportDetails;
		let deviceDetails;
		if (page === 'asset') {
			deviceDetails = useSelector(({ assetsApp }) => assetsApp.details.deviceDetails);
			globalCode.transit = globalCode.area;
		} else if (page === 'transport')
			transportDetails = useSelector(({ transportApp }) => transportApp.transport.transportDetails);
		const [currentPingMap, setCurrentPingMap] = useState({
			lat: latState,
			lng: lngState
		});
		const [zoomMap, setZoomMap] = useState(4);
		const [geofencing, setGeofencing] = useState(null);
		const mapRef = useRef();
		const mapsRef = useRef();
		const maxZoomRef = 18;

		const createMapOptions = maps => {
			return {
				panControl: true,
				mapTypeControl: false,
				scrollwheel: true,
				fullscreenControl: false,
				clickableIcons: false,
				minZoom: 2,
				maxZoom: maxZoomRef,
				styles: [
					{
						featureType: 'poi',
						elementType: 'labels',
						stylers: [{ visibility: 'off' }]
					},
					{
						featureType: 'poi.business',
						stylers: [{ visibility: 'off' }]
					},
					{
						featureType: 'transit',
						elementType: 'labels.icon',
						stylers: [{ visibility: 'off' }]
					}
				]
			};
		};

		const handleDefaultCenter = (map, maps) => {
			if (!latState && !lngState) {
				setZoomMap(5);
				map.setZoom(5);
			} else {
				const boundsByPointsPosition = new maps.LatLngBounds();

				const locPosition = new maps.LatLng(latState, lngState);
				boundsByPointsPosition.extend(locPosition);

				map.fitBounds(boundsByPointsPosition);
				map.panToBounds(boundsByPointsPosition);
				setZoomMap(12);
				map.setZoom(12);
			}
		};

		const isEmpty = obj => {
			return Object.keys(obj).length === 0;
		};

		const fetchAddressFromCoordinate = async (latPing, lngPing) => {
			const response = await axios.get(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latPing},${lngPing}&key=${process.env.REACT_APP_GOOGLE_KEY}&language=en`
			);

			setCurrentPingMap({
				lat: latPing,
				lng: lngPing
			});

			return response;
		};

		const handleSubmit = async () => {
			const responseFromGoogle = await fetchAddressFromCoordinate(currentPingMap.lat, currentPingMap.lng);
			await saveAddress(responseFromGoogle);
		};

		const saveAddress = async response => {
			const validAddress = {};

			if (response.data.results.length > 0) {
				const results = await geocodeByAddress(response.data.results[0].formatted_address);
				if (results[0].address_components[0].types[0] === 'plus_code' && results[0].address_components[1]) {
					results[0].formatted_address = results[0].formatted_address.substring(
						results[0].address_components[0].long_name.length + 1
					);
				}
				const addressReq = results[0].address_components.reduce((accu, value) => {
					if (value.types[0] === 'street_number') accu.number = value.long_name;
					if (value.types[0] === 'route') {
						if (accu.number) {
							accu.street = `${accu.number} ${value.long_name}`;
							delete accu.number;
						} else {
							accu.street = value.long_name;
						}
					}
					if (value.types[0] === 'locality' || value.types[0] === 'postal_town') accu.city = value.long_name;
					if (value.types[0] === 'country') accu.country = value.long_name;
					if (value.types[0] === 'postal_code') {
						accu.postCode = value.long_name;
					}
					return accu;
				}, {});

				if (currentDialogPing === 'start') validAddress.fullAddressStart = results[0].formatted_address;
				if (currentDialogPing.includes('step')) validAddress.fullAddress = results[0].formatted_address;
				if (currentDialogPing === 'end') validAddress.fullAddressEnd = results[0].formatted_address;

				if (isEmpty(addressReq)) {
					validAddress.address = {
						country: results[0].formatted_address
					};
				} else {
					validAddress.address = addressReq;
				}

				validAddress.longitude = currentPingMap.lng;
				validAddress.latitude = currentPingMap.lat;
				validAddress.step = currentDialogPing.includes('step') ? 'transit' : currentDialogPing;

				if (currentDialogPing.includes('step')) {
					const copyGlobalCodeTransit = globalCode.transit;
					const stepNumber = currentDialogPing.replace('step', '');
					const parseStepNumber = parseInt(stepNumber, 10);

					if (copyGlobalCodeTransit[parseStepNumber - 1]) {
						copyGlobalCodeTransit[parseStepNumber - 1].code = response.data.plus_code.global_code;
						copyGlobalCodeTransit[parseStepNumber - 1].coordinate.lat = currentPingMap.lat;
						copyGlobalCodeTransit[parseStepNumber - 1].coordinate.lng = currentPingMap.lng;
						copyGlobalCodeTransit[parseStepNumber - 1].formattedAddress = results[0].formatted_address;
						copyGlobalCodeTransit[parseStepNumber - 1].fullAddress = results[0].formatted_address;
					}

					if (!type) {
						if (page === 'transport')
							transportDetails.trip[parseStepNumber - 1].fullAddress = results[0].formatted_address;
						else if (page === 'asset')
							deviceDetails.area[parseStepNumber - 1].fullAddress = results[0].formatted_address;
						else if (page === 'createPresetAlert')
							dataCreatePresetAlert.fullAddress = results[0].formatted_address;
					}

					setGlobalCode({
						...globalCode,
						transit: copyGlobalCodeTransit
					});
					getUpdateAddressTransit(validAddress, parseStepNumber - 1);
				} else {
					setGlobalCode({
						...globalCode,
						[currentDialogPing]: {
							coordinate: {
								lat: currentPingMap.lat,
								lng: currentPingMap.lng
							},
							code: response.data.plus_code.global_code,
							formattedAddress: results[0].formatted_address,
							fullAddress: results[0].formatted_address
						}
					});
					getUpdateAddress && getUpdateAddress(validAddress, id);
				}
			} else {
				const latAndLngParsed = `${parseFloat(currentPingMap.lat).toFixed(5)}, ${parseFloat(
					currentPingMap.lng
				).toFixed(5)}`;
				if (currentDialogPing === 'start') validAddress.fullAddressStart = latAndLngParsed;
				if (currentDialogPing.includes('step')) validAddress.fullAddress = latAndLngParsed;
				if (currentDialogPing === 'end') validAddress.fullAddressEnd = latAndLngParsed;

				validAddress.address = {
					country: latAndLngParsed
				};
				validAddress.longitude = currentPingMap.lng;
				validAddress.latitude = currentPingMap.lat;
				validAddress.step = currentDialogPing.includes('step') ? 'transit' : currentDialogPing;

				if (currentDialogPing.includes('step')) {
					const copyGlobalCodeTransit = globalCode.transit;
					const stepNumber = currentDialogPing.replace('step', '');
					const parseStepNumber = parseInt(stepNumber, 10);

					copyGlobalCodeTransit[parseStepNumber - 1].code = response.data.plus_code.global_code;
					copyGlobalCodeTransit[parseStepNumber - 1].coordinate.lat = currentPingMap.lat;
					copyGlobalCodeTransit[parseStepNumber - 1].coordinate.lng = currentPingMap.lng;
					copyGlobalCodeTransit[parseStepNumber - 1].formattedAddress = latAndLngParsed;
					copyGlobalCodeTransit[parseStepNumber - 1].fullAddress = latAndLngParsed;

					if (!type) {
						if (page === 'transport')
							transportDetails.trip[parseStepNumber - 1].fullAddress = latAndLngParsed;
						else if (page === 'asset')
							deviceDetails.area[parseStepNumber - 1].fullAddress = latAndLngParsed;
						else if (page === 'createPresetAlert') dataCreatePresetAlert.fullAddress = latAndLngParsed;
					}

					setGlobalCode({
						...globalCode,
						transit: copyGlobalCodeTransit
					});
					getUpdateAddressTransit(validAddress, parseStepNumber - 1);
				} else {
					setGlobalCode({
						...globalCode,
						[currentDialogPing]: {
							coordinate: {
								lat: currentPingMap.lat,
								lng: currentPingMap.lng
							},
							code: response.data.plus_code.global_code,
							formattedAddress: latAndLngParsed,
							fullAddress: latAndLngParsed
						}
					});
					getUpdateAddress(validAddress, id);
				}
			}
			handleCloseDialogMapPing();
		};

		const generateTitle = () => {
			if (!currentDialogPing) return <p>'pas de dialog ping'</p>;
			if (currentDialogPing.includes('step')) {
				const stepNumber = currentDialogPing.replace('step', '');
				const parseStepNumber = parseInt(stepNumber, 10);

				return <p>{`${t('ETAPE')} ${parseStepNumber}`}</p>;
			}
			if (currentDialogPing === 'start') return <p>{t('DEPARTURE')}</p>;
			if (currentDialogPing === 'end') return <p>{t('DESTINATION_END')}</p>;
		};

		const generateCircle = (map, maps, radius, lat, lng) => {
			const circle = new maps.Circle({
				strokeColor: '#D4E5FF',
				strokeOpacity: 0.5,
				strokeWeight: 2,
				fillColor: '#D4E5FF',
				fillOpacity: 0.5,
				map,
				center: { lat, lng },
				radius: radius * 1000
			});

			const objectCircle = {
				center: { lat, lng },
				circle
			};
			setGeofencing(objectCircle);
			circle.setMap(map);
		};

		const redrawGeofence = useCallback(
			(lat, lng) => {
				if (geofencing) {
					geofencing.circle.setMap(null);
				}
				if (mapRef.current && mapsRef.current) {
					generateCircle(mapRef.current, mapsRef.current, geofenceTransport, lat, lng);
				}
			},
			// eslint-disable-next-line
			[geofenceTransport, currentPingMap]
		);

		useEffect(() => {
			redrawGeofence(currentPingMap.lat, currentPingMap.lng);
			// eslint-disable-next-line
		}, [geofenceTransport, currentPingMap]);

		return (
			<Dialog
				onClose={handleCloseDialogMapPing}
				aria-labelledby="dialog-map-ping"
				open={openDialogPingMap}
				onClick={e => e.stopPropagation()}
				fullWidth
				maxWidth={isMobileDevice ? 'xl' : 'md'}
			>
				<div style={{ height: '90vh', width: '100%' }} className="relative">
					<BlockTitle>{generateTitle()}</BlockTitle>
					<ContainerButtons>
						<ButtonStyled
							type="button"
							className="py-7 px-10"
							onClick={handleCloseDialogMapPing}
							secondary
							fill="true"
						>
							{t('BOUTON_CANCEL')}
						</ButtonStyled>
						<ButtonStyled
							type="button"
							disabled={!currentPingMap.lat && !currentPingMap.lat}
							className="py-7 px-10"
							onClick={() => handleSubmit()}
						>
							{t('BOUTON_FINISHED')}
						</ButtonStyled>
					</ContainerButtons>
					<GoogleMapReact
						id="GooglePingMap"
						bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
						options={createMapOptions}
						defaultCenter={{
							lat: 46,
							lng: 2
						}}
						yesIWantToUseGoogleMapApiInternals
						onGoogleApiLoaded={({ map, maps }) => {
							mapRef.current = map;
							mapsRef.current = maps;
							setCurrentPingMap({
								lat: latState,
								lng: lngState
							});
							map.setOptions({ styles: ['hide'] });
							handleDefaultCenter(map, maps);
						}}
						zoom={zoomMap}
						onChange={propsChange => {
							setZoomMap(propsChange.zoom);
						}}
						onClick={({ lat, lng }) => {
							fetchAddressFromCoordinate(lat, lng);
						}}
					>
						{currentPingMap.lat && currentPingMap.lat && (
							<PingMarker lat={currentPingMap.lat} lng={currentPingMap.lng}>
								<BlockPing>
									<TransitIconStyled />
								</BlockPing>
							</PingMarker>
						)}
					</GoogleMapReact>
				</div>
			</Dialog>
		);
	}
);

const BlockPing = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const BlockTitle = styled.div`
	display: flex;
	padding: 10px;
	z-index: 10;
	position: absolute;
	left: 20px;
	top: 20px;
	border-radius: 8px;
	background-color: white;
	box-shadow: ${props => props.theme.palette.stracker.boxShadowCard};
	color: ${props => props.theme.palette.stracker.contrastTextDark};
	font-weight: 600;
	font-size: 24px;
	text-transform: capitalize;
`;

const ContainerButtons = styled.div`
	width: 50%;
	display: flex;
	justify-content: space-evenly;
	padding: 10px 0;
	z-index: 10;
	position: absolute;
	left: 50%;
	bottom: 0;
	border-radius: 8px;

	transform: translate(-50%, -50%);
	button {
		font-size: 20px;
	}
`;

const TransitIconStyled = styled.div`
	width: 20px;
	height: 20px;
	background-color: white;
	border-radius: 50%;

	&::before {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #074ee8;
	}
`;

export default DialogPingMap;
