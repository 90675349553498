import _ from '@lodash';
import * as Actions from '../actions';

const initialState = {
	entities: [],
	notifications: {},
	total: 0,
	searchText: '',
	orderBy: '',
	success: {},
	typeRetour: '',
	message: '',
	status: ''
};

const notifsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_SC_NOTIFICATIONS:
			return [...action.payload];
		case Actions.GET_SC_NOTIFICATIONS_EMAIL: {
			return {
				...state,
				success: {},
				total: action.payload.total,
				// notificationsEmail: _.keyBy(action.payload, 'id')
				notificationsEmail: action.payload
			};
		}
		case Actions.GET_SC_NOTIFICATIONS_SMS: {
			return {
				...state,
				success: {},
				total: action.payload.total,
				// notificationsSms: _.keyBy(action.payload, 'id')
				notificationsSms: action.payload
			};
		}
		case Actions.GET_SC_NOTIFICATIONS_MYNOTIFICATION: {
			return {
				...state,
				total: action.payload.total,
				notifications: _.toArray(action.payload, 'id')
			};
		}
		case Actions.CREATE_NOTIFICATION_SUCCESS: {
			return {
				...state,
				success: {
					typeRetour: 'creation',
					status: action.payload.status,
					message: action.payload.data
				}
			};
		}
		case Actions.UPDATE_NOTIFICATION_SUCCESS: {
			return {
				...state,
				success: {
					typeRetour: 'update',
					status: action.payload.status,
					message: action.payload.data
				}
			};
		}
		case Actions.DELETE_NOTIFICATION_SUCCESS: {
			return {
				...state,
				success: {
					typeRetour: 'delete',
					status: action.payload.status,
					message: action.payload.data
				}
			};
		}
		case Actions.SET_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		default:
			return state;
	}
};

export default notifsReducer;
