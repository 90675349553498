import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import errorsConfigs from 'app/main/errors/errorsConfigs';
import authConfigs from 'app/main/authe/authConfigs';
import appsConfigs from 'app/main/apps/appsConfigs';
import supplychainConfig from 'app/main/supplychain/supplychainConfig';
// import LandingPage from './LandingPage';

/* function setAdminAuth(configs) {
	return configs.map(config => _.merge({}, config, { auth: authRoles.user }));
} 
const routeConfigs = [...setAdminAuth([...appsConfigs, LogoutConfig]), LoginConfig]; */

const routeConfigs = [...errorsConfigs, ...authConfigs, ...appsConfigs, ...supplychainConfig];

const routes = [
	// The individual route configs which has auth option won't be overridden.
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	// if you want to make whole app auth protected by default change defaultAuth for example:
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['superAdmin', 'userAdmin', 'user']),
	{
		path: '/',
		exact: true,
		// auth: null,
		// component: LandingPage
		component: () => <Redirect to="/transport" />
	},
	{
		path: '/share',
		exact: true,
		// auth: null,
		// component: LandingPage
		component: () => <Redirect to="/share" />
	},
	{
		component: () => <Redirect to="/error-404" />
	}
];

export default routes;
