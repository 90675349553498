import React, { useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import AlertTriangleIconBlue from '../../../custom-icons/AlertTriangleIconBlue';
import { TitleTabSettings } from '../../../../../@stracker/components/title/TitleTabSettings';
import { ButtonStyled } from '../../../../../@stracker/components/StyledComponents/ButtonStyled';
import CancelIcon from '@material-ui/icons/Cancel';
import MenuIcon from '@material-ui/icons/Menu';

function AlertsHeader({
	setHeightHeader,
	setDisplayAddAlert,
	displayAddAlert,
	handleCloseDialogSettings,
	burgerIsOpen,
	setBurgerIsOpen,
	displayMenuBurger
}) {
	const theme = useTheme();
	const { t } = useTranslation('alerts');
	const alertHeaderRef = useRef(null);

	const user = useSelector(({ auth }) => auth.user);

	window.addEventListener(
		'resize',
		function (event) {
			if (alertHeaderRef.current) {
				const alertHeaderParentHeight = alertHeaderRef.current.offsetHeight;
				setHeightHeader(alertHeaderParentHeight);
			}
		},
		true
	);

	useLayoutEffect(() => {
		if (alertHeaderRef.current) {
			const alertHeaderParentHeight = alertHeaderRef.current.offsetHeight;
			setHeightHeader(alertHeaderParentHeight);
		}
		// eslint-disable-next-line
	}, [alertHeaderRef]); //Listen event resize window

	return (
		<TitleTabSettings ref={alertHeaderRef} className="justify-between">
			{displayMenuBurger && (
				<MenuIcon className="cursor-pointer mr-10" onClick={() => setBurgerIsOpen(!burgerIsOpen)} />
			)}
			<div className="flex items-center w-full">
				<AlertTriangleIconBlue color={theme.palette.stracker.contrastText} />
				{displayAddAlert === false ? <h4>{t('MY_FAV_ALERT')}</h4> : <h4>{t('CREATE_NEW_FAV_ALERT')}</h4>}
			</div>
			{displayMenuBurger && (
				<CancelIcon className=" cursor-pointer ml-auto" onClick={handleCloseDialogSettings} />
			)}

			{displayAddAlert === false ? (
				<div>
					<ButtonStyled
						type="button"
						className={clsx('mr-20', 'ml-5 py-7 px-10 w-max')}
						style={{ display: user.role !== 'userAdmin' && 'none' }}
						aria-label="ADD"
						onClick={() => setDisplayAddAlert(true)}
					>
						{t('ADDALERT')}
					</ButtonStyled>
				</div>
			) : (
				''
			)}
		</TitleTabSettings>
	);
}

export default AlertsHeader;
