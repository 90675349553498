import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import ThermometerIcon from '@stracker/customicons/ThermometerIcon';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IncrementDecrement from '@stracker/components/IncrementDecrement';

function Temperature({ allAlerts, setAllAlerts }) {
	const theme = useTheme();

	const handleClose = () => {
		const allAlertCpy = { ...allAlerts };
		allAlertCpy.temperature.display = false;
		setAllAlerts(allAlertCpy);
	};

	const handleChange = (ev, type, title) => {
		let value = title === 'max' ? allAlerts.temperature.max : allAlerts.temperature.min;
		if (type === 'temperature') {
			if (ev === 'decrement') value -= 1;
			else if (ev === 'increment') value += 1;
			else if (ev !== 'decrement' && ev !== 'increment') value = ev;
		}
		const allAlertCpy = { ...allAlerts };
		if (ev !== 'increment' && ev !== 'decrement') {
			title === 'max' ? (allAlertCpy.temperature.max = value) : (allAlertCpy.temperature.min = value);
			setAllAlerts(allAlertCpy);
		} else {
			title === 'max' ? (allAlertCpy.temperature.max = value) : (allAlertCpy.temperature.min = value);
			if (allAlertCpy.temperature.max > allAlertCpy.temperature.min && (ev === 'increment' || ev === 'decrement'))
				setAllAlerts(allAlertCpy);
			else if (ev === 'increment') {
				allAlertCpy.temperature.max =
					title === 'max' ? allAlertCpy.temperature.max : (allAlertCpy.temperature.max += 1);
				allAlertCpy.temperature.min =
					title === 'min' ? allAlertCpy.temperature.min : (allAlertCpy.temperature.min += 1);
				setAllAlerts(allAlertCpy);
			} else if (ev === 'decrement') {
				allAlertCpy.temperature.max =
					title === 'max' ? allAlertCpy.temperature.max : (allAlertCpy.temperature.max -= 1);
				allAlertCpy.temperature.min =
					title === 'min' ? allAlertCpy.temperature.min : (allAlertCpy.temperature.min -= 1);
				setAllAlerts(allAlertCpy);
			}
		}
	};

	return (
		<div
			className="flex"
			style={{
				paddingLeft: '10px',
				borderBottom: 'solid 1px',
				borderColor: theme.palette.stracker.colorOutlined
			}}
		>
			<ListItem
				role={undefined}
				disableGutters
				button
				style={{
					width: 'auto',
					paddingTop: '20px',
					paddingBottom: '20px'
				}}
			>
				<ListItemIcon onClick={() => handleClose()} className="min-w-0" style={{ marginRight: '14px' }}>
					<CircleDeleteIcon />
				</ListItemIcon>
				<ListItemText
					primary={
						<span className="flex items-center">
							<ThermometerIcon className="mr-5" />
							<p variant="body2" className="ml-5 font-semibold text-16">
								Température
							</p>
						</span>
					}
				/>
			</ListItem>
			<ListItemText
				className={'visible'}
				style={{ width: '100%' }}
				primary={
					<span className="flex items-center justify-end">
						<IncrementDecrement
							handleChange={handleChange}
							value={allAlerts.temperature.min}
							valueUnits={'°C'}
							title={'min'}
							type={'temperature'}
						/>
						<IncrementDecrement
							handleChange={handleChange}
							value={allAlerts.temperature.max}
							valueUnits={'°C'}
							title={'max'}
							type={'temperature'}
						/>
					</span>
				}
			/>
		</div>
	);
}

export default Temperature;
