import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

function HumidityIcon({ marginTop }) {
	const theme = useTheme();
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ marginTop: marginTop }}
		>
			<path
				d="M9.00016 2.01758L13.2452 6.26258C14.0847 7.10154 14.6565 8.17063 14.8883 9.33462C15.1202 10.4986 15.0016 11.7052 14.5476 12.8018C14.0936 13.8984 13.3245 14.8357 12.3377 15.4952C11.351 16.1546 10.1908 16.5066 9.00391 16.5066C7.81705 16.5066 6.65686 16.1546 5.67008 15.4952C4.6833 14.8357 3.91427 13.8984 3.46026 12.8018C3.00625 11.7052 2.88766 10.4986 3.11949 9.33462C3.35132 8.17063 3.92315 7.10154 4.76266 6.26258L9.00016 2.01758Z"
				stroke={theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default HumidityIcon;
