import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import * as Actions from '../store/actions';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	paddSelect: {
		paddingRight: '35px'
	},
	'@global': {
		'.MpersoDialod .confirmValidate': {
			color: '#fff',
			backgroundColor: '#074EE8'
		},
		'.MpersoDialod .confirmCancel': {
			color: '#023E65',
			border: '1px solid #A8C1F7',
			backgroundColor: '#fff',
			position: 'absolute',
			left: '8px'
		},
		'.MuiList-root.MuiMenu-list.MuiList-padding li:hover': {
			backgroundColor:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.input} !important`
					: `${theme.palette.stracker.body} !important`
		}
	}
}));

const ITEM_HEIGHT = 48;

function MoreBtn({ setPageRouter, contractorData, setSelectedContractor, filteredData, setFilteredData }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('contractors');

	const [deleteWindow, setDeleteWindow] = useState(false);
	const [displayMenu, setDisplayMenu] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	function handleEditContractor() {
		setPageRouter('editContractor');
		setSelectedContractor(contractorData);
	}

	const handleClick = event => {
		setDisplayMenu(!displayMenu);
		setAnchorEl(event.currentTarget);
	};

	const deleteContractor = () => {
		const memberInfo = {
			idCustomer: contractorData.idCustomer,
			idContractor: contractorData.idContractor
		};
		dispatch(Actions.deleteContractor(memberInfo));
		let filteredDataCpy = filteredData;
		// eslint-disable-next-line
		filteredDataCpy.map((data, i) => {
			if (data.idContractor === contractorData.idContractor) {
				filteredDataCpy.splice(i, 1);
			}
		});
		setFilteredData([...filteredDataCpy]);

		setDeleteWindow(false);
		setDisplayMenu(false);
	};

	return (
		<div>
			{deleteWindow && (
				<Dialog
					className="MpersoDialod"
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					open={deleteWindow}
					onBackdropClick={e => {
						e.stopPropagation();
						setDeleteWindow(false);
					}}
				>
					<DialogTitle id="alert-dialog-title" />
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{t('CONFIRM_DELETE_CONTRACTOR')}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							className="confirmCancel rounded-8 normal-case"
							onClick={() => setDeleteWindow(false)}
							color="primary"
						>
							{t('CANCEL')}
						</Button>
						<Button
							className="confirmValidate rounded-8 normal-case"
							color="primary"
							onClick={deleteContractor}
						>
							{t('DELETE')}
						</Button>
					</DialogActions>
				</Dialog>
			)}

			<IconButton
				edge="end"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<div className={clsx(classes.paddSelect)}>
				<Menu
					open={displayMenu}
					anchorEl={anchorEl}
					onBackdropClick={e => {
						e.stopPropagation();
						setDisplayMenu(false);
					}}
					keepMounted
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: '20ch'
						}
					}}
				>
					<MenuItem onClick={handleEditContractor} value={10}>
						{t('CONTRACTORMODIF')}
					</MenuItem>
					<MenuItem onClick={() => setDeleteWindow(true)} value={10}>
						{t('CONTRACTORSUPPR')}
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
}

export default MoreBtn;
