import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import ClockIcon from 'app/main/custom-icons/ClockIcon';
import * as Actions from './store/actions';
import { ButtonStyled } from '../../../../@stracker/components/StyledComponents/ButtonStyled';
import translationDate from '../../../../utils/translationDate';

const useStyles = makeStyles({
	avatar: {
		width: '100% !important',
		height: '100% !important',
		color: '#EDA124',
		border: '2px solid #fff',
		fontSize: '1.5rem',
		boxShadow: '0px 4px 4px rgb(7 78 232 / 15%)',
		fontWeight: 'bold',
		backgroundColor: '#FAE3BD'
	}
});

const NotificationsItem = ({ notification }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const userLog = useSelector(({ auth }) => auth.user);
	const { t } = useTranslation('notifications');
	const classes = useStyles();
	const lang = useSelector(({ i18n }) => i18n.language);
	moment.locale(lang);

	const handleNotificationRead = id => {
		dispatch(Actions.updateNotificationRead(userLog.id, userLog.data.idCustomer, id));
	};

	const { firstName, lastName, content, idNotification, isRead, avatarUrl, createdAt } = notification;

	return (
		<div
			className="flex flex-col justify-between items-center p-16 sm:flex-row md:p-24 border-b-1"
			style={{
				color: `${
					theme.palette.type === 'light'
						? theme.palette.stracker.colorOutlined
						: theme.palette.stracker.colorOutlined
				}`,
				borderColor: `${
					theme.palette.type === 'light'
						? theme.palette.stracker.colorOutlined
						: theme.palette.stracker.colorOutlined
				}`
			}}
		>
			<div className="flex items-center w-full sm:w-9/12 md:w-10/12" style={{ height: 'inherit' }}>
				<div className="flex justify-center w-2/12 md:w-1/12">
					<div className={`h-48 w-48 md:h-72 md:w-72 relative ${isRead === 0 ? 'bubbleNotifications' : ''}`}>
						{avatarUrl !== null ? (
							<img
								src={avatarUrl}
								alt="avatar"
								className="object-cover w-40 h-40 md:w-full md:h-full rounded-full"
								style={{
									border: '2px solid #FFFFFF',
									boxShadow: '0px 4px 4px rgba(7, 78, 232, 0.15)'
								}}
							/>
						) : (
							<Avatar className={clsx(classes.avatar, 'uppercase')}>
								{firstName.charAt(0)}
								{lastName.charAt(0)}
							</Avatar>
						)}
					</div>
				</div>

				<div className="flex flex-col ml-16 w-10/12 md:w-11/12">
					<p
						className={`mb-2 ${isRead === 1 ? 'font-semibold' : 'font-extrabold'}`}
						style={{
							color: `${
								theme.palette.type === 'light'
									? theme.palette.stracker.contrastTextDark
									: theme.palette.stracker.contrastTextRegular
							}`
						}}
					>
						{content}
					</p>

					<p className="flex py-10" style={{ color: theme.palette.stracker.contrastTextLight }}>
						<span className="mr-5">
							<ClockIcon size={18} color={theme.palette.stracker.contrastTextLight} />
						</span>
						{translationDate(lang, createdAt)}
					</p>
				</div>
			</div>
			<div className="flex justify-center w-full sm:w-3/12 md:w-2/12">
				{isRead === 0 && (
					<ButtonStyled
						type="button"
						className="ml-5"
						onClick={() => handleNotificationRead(idNotification)}
						secondary
					>
						{t('BTN_READ_NOTIFICATION_TEXT')}
					</ButtonStyled>
				)}
			</div>
		</div>
	);
};

export default NotificationsItem;
