import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import TrackNavBadge from '../TrackNavBadge';
import StatusIcon from '../StatusIcon';

const useStyles = makeStyles(theme => ({
	item: props => ({
		height: 40,
		width: 'calc(100% - 66px)',
		borderRadius: '0 20px 20px 0',
		paddingRight: 12,
		paddingTop: 12,
		paddingBottom: 12,
		cursor: 'pointer',
		textDecoration: 'none!important',
		fontWeight: 600,
		font: 'Open Sans',
		color: theme.palette.stracker.contrastTextDark,
		paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
		'&.active': {
			fontSize: ' 1.6rem',
			borderRadius: '8px',
			border: '2px solid #fff',
			boxShadow: `0px 4px 12px ${theme.palette.secondary.colorShadow2}`, // '0px 4px 4px rgb(7 78 232 / 15%)',
			backgroundColor: theme.palette.background.default2, // to #373C3F
			color: `${theme.palette.stracker.contrastBgButton}`, // `${theme.palette.primary.contrastText}`, //
			// pointerEvents: 'none',
			transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
			'& .list-item-text-primary': {
				color: 'inherit'
			},
			'& .list-item-icon': {
				color: 'inherit'
			},
			'& .active:after': {
				content: 'a',
				border: '1px solid #074EE8',
				height: '20px'
			}
		},
		'&.active svg': {
			display: 'block',
			color: `${theme.palette.stracker.contrastText} !important`
		},
		'& .active:after': {
			content: 'a',
			border: `1px solid ${theme.palette.primary.contrastText}`,
			height: '20px'
		},
		'& .list-item-icon': {
			marginRight: 16
		},
		'& .list-item-text': {}
	}),
	'@global': {
		'.navigation .list-item.active:after': {
			content: '',
			border: '1px solid red', // + theme.palette.stracker.contrastBgButton, //#074EE8,
			height: '20px'
		},
		'.navigation .Mitem .RectangleIcon': {
			display: 'none'
		},
		'.navigation .Mitem.active .RectangleIcon': {
			display: 'block',
			height: '20px',
			color: theme.palette.stracker.contrastText
		}
	}
}));

function TrackNavVerticalItem(props) {
	const userRole = useSelector(({ auth }) => auth.user.role);
	const notif = useSelector(({ notificationsData }) => notificationsData.notificationsData);
	const dispatch = useDispatch();

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const { item, nestedLevel } = props;
	const classes = useStyles({
		itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24
	});

	const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	if (!hasPermission) {
		return null;
	}

	if (item.title === 'Trackers' && userRole === 'user') return null;
	if (item.id === 'client' && userRole !== 'superAdmin') return null;

	return (
		<ListItem
			button
			component={NavLinkAdapter}
			to={item.url}
			disabled={item.id === 'analytics' ? true : false}
			onClick={ev => mdDown && dispatch(navbarCloseMobile())}
			exact={item.exact}
			className={clsx(
				classes.item,
				item.id === 'tasks' && userRole === 'user' ? 'hidden' : 'Mitem list-item py-20 my-4'
				//item.id === 'fashionhub' && user.data.vertical === '#LUXE' ? 'Mitem list-item py-20 my-4' : 'hidden'
			)}
			activeClassName={clsx(item.url === props.location.pathname ? 'pointer-events-none active' : 'active')}
		>
			{item.icon && <StatusIcon status={item.icon} className={classes.itemIcon} />}

			<ListItemText
				className="font-semibold list-item-text"
				primary={item.title}
				classes={{ primary: 'text-16 list-item-text-primary' }}
			/>
			{((item.id === 'transport' && notif.counterTransport > 0) ||
				(item.id === 'cotations' && notif.counterCotation > 0)) && (
				<div
					style={{
						backgroundColor: theme.palette.stracker.contrastBgButton,
						color: 'white',
						borderRadius: '32px',
						fontSize: '14px',
						padding: '1px 8px',
						fontWeight: '400'
					}}
				>
					{(item.id === 'transport' && notif.counterTransport) ||
						(item.id === 'cotations' && notif.counterCotation)}
				</div>
			)}

			{item.badge && <TrackNavBadge badge={item.badge} />}
		</ListItem>
	);
}

TrackNavVerticalItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.string,
		url: PropTypes.string
	})
};

TrackNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(TrackNavVerticalItem));

export default NavVerticalItem;

/*
{item.id === 'transport' && notif.counterTransport && (
				<div
					style={{
						backgroundColor: theme.palette.stracker.contrastBgButton,
						color: 'white',
						borderRadius: '32px',
						fontSize: '14px',
						padding: '1px 8px',
						fontWeight: '400'
					}}
				>
					{item.id === 'transport' && notif.counterTransport}
				</div>
			)}
			{item.id === 'cotations' && notif.counterCotation && (
				<div
					style={{
						backgroundColor: theme.palette.stracker.contrastBgButton,
						color: 'white',
						borderRadius: '32px',
						fontSize: '14px',
						padding: '1px 8px',
						fontWeight: '400'
					}}
				>
					{item.id === 'cotations' && notif.counterCotation}
				</div>
			)}
*/
