const locale = {
	TITLE:
		"Paramètrage des destinataires des notifications. Ces destinataires recevront l'ensemble des notifications liées à votre compte.",
	CONFIRM_DELETE_NOTIF: 'Etes-vous sûr de vouloir supprimer la notification ?',
	BOUTON_CANCEL: 'Annuler',
	BOUTON_VALIDER: 'Valider',
	CANCEL: 'Annuler',
	CREATE: 'Valider',
	UPDATE: 'Actualiser',
	NOTIFSMS: 'Notifications par SMS',
	NOTIFEMAIL: 'Notifications par email',
	ADDEMAILS: 'Ajouter un destinataire',
	ADDSMS: 'Ajouter un destinataire',
	NOEMAIL: 'Adresse email non valide',
	MINSMS: '10 caractères minimum avec indicateur du pays',
	EMAIL: 'Email',
	TEL: 'Téléphone',
	DELETESUCC: 'Destinataire supprimé avec succès.',
	DELETERR: 'Attention. Une erreur est survenue lors de la suppression.',
	TITLETYPE: 'Notifications par',
	UPDATESUCC: 'Destinataire mis à jour avec succès.',
	UPDATERR: 'Attention. Une erreur est survenue lors de la mise à jour.',
	CREATESUCC: 'Destinataire créé avec succès.',
	CREATERR: 'Attention. Une erreur est survenue lors de la création.',
	AJOUTNOTIFS: 'Ajouter des notifications'
};

export default locale;
