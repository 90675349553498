import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const CrossHair = ({ className, size, color }) => {
	const theme = useTheme();
	return (
		<svg
			className={className}
			width={size || 18}
			height={size || 18}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15Z"
				stroke={color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17 9H16"
				stroke={color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 9H1"
				stroke={color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 2V1"
				stroke={color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 16V17"
				stroke={color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.1875 10.875C10.1195 10.875 10.875 10.1195 10.875 9.1875C10.875 8.25552 10.1195 7.5 9.1875 7.5C8.25552 7.5 7.5 8.25552 7.5 9.1875C7.5 10.1195 8.25552 10.875 9.1875 10.875Z"
				stroke={color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CrossHair;
