import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

function LocalisationIcon(props) {
	const theme = useTheme();

	return (
		<svg
			width={props.size || '18'}
			height={props.size || '18'}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 7.72727C15 12.1818 9 16 9 16C9 16 3 12.1818 3 7.72727C3 6.20831 3.63214 4.75155 4.75736 3.67748C5.88258 2.60341 7.4087 2 9 2C10.5913 2 12.1174 2.60341 13.2426 3.67748C14.3679 4.75155 15 6.20831 15 7.72727Z"
				fill=""
				stroke={theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
				fill=""
				stroke={theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default LocalisationIcon;
