import React from 'react';

function AlertTriangleBlue(props) {
	return (
		<div className="">
			<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11.1478 4.18164L1.97199 19.5C1.78281 19.8276 1.68271 20.1991 1.68165 20.5774C1.68059 20.9557 1.77861 21.3277 1.96596 21.6564C2.1533 21.9851 2.42345 22.259 2.74951 22.4508C3.07558 22.6427 3.44619 22.7458 3.82449 22.75H22.1762C22.5545 22.7458 22.9251 22.6427 23.2511 22.4508C23.5772 22.259 23.8474 21.9851 24.0347 21.6564C24.222 21.3277 24.3201 20.9557 24.319 20.5774C24.318 20.1991 24.2178 19.8276 24.0287 19.5L14.8528 4.18164C14.6597 3.86326 14.3878 3.60002 14.0633 3.41734C13.7388 3.23465 13.3727 3.13867 13.0003 3.13867C12.6279 3.13867 12.2619 3.23465 11.9374 3.41734C11.6129 3.60002 11.341 3.86326 11.1478 4.18164V4.18164Z"
					stroke={props.color || 'currentColor'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13 9.75V14.0833"
					stroke={props.color || 'currentColor'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13 18.4166H13.0108"
					stroke={props.color || 'currentColor'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default AlertTriangleBlue;
