const locale = {
	TITLE: 'Settings for sending notifications',
	CONFIRM_DELETE_NOTIF: 'Delete confirmation',
	BOUTON_CANCEL: 'Cancel',
	BOUTON_VALIDER: 'Validate',
	CANCEL: 'Cancel',
	CREATE: 'Validate',
	UPDATE: 'Update',
	NOTIFSMS: 'SMS notifications',
	NOTIFEMAIL: 'Email notifications',
	ADDEMAILS: 'Add',
	ADDSMS: 'Add',
	NOEMAIL: 'This is not a valid email',
	MINSMS: 'Min character length is 10',
	EMAIL: 'Email',
	TEL: 'Phone',
	DELETESUCC: 'Deletion completed successfully.',
	DELETERR: 'Error while deleting.',
	TITLETYPE: 'Notifications by',
	UPDATESUCC: 'Update successfully completed.',
	UPDATERR: 'Error during update.',
	CREATESUCC: 'Creation successfully completed.',
	CREATERR: 'Error during creation.',
	AJOUTNOTIFS: 'Add notifications',
};

export default locale;
