import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import DeleteIcon from '../../../custom-icons/DeleteIcon2';
import MappinIcon from '@stracker/customicons/LocalisationIcon';
import HumidityIcon from '@stracker/customicons/HumidityIcon';
import BatteryIcon from '@stracker/customicons/BatteryIcon';
import ThermometerIcon from '@stracker/customicons/ThermometerIcon';

function CardByPreset({ users, printDialog, setDisplayAddAlert, setAlertsData }) {
	const theme = useTheme();

	const changeAlert = () => {
		setAlertsData(users);
		setDisplayAddAlert(true);
	};

	return (
		<ListItemStyled
			disableGutters
			className=" flex justify-between cursor-pointer"
			onClick={() => {
				changeAlert();
			}}
		>
			<div className="flex">
				<IconButton
					className="p-8"
					onClick={e => {
						e.stopPropagation();
						printDialog(users.name);
					}}
				>
					<DeleteIcon
						boderColorM={theme.palette.stracker.contrastOtherButton}
						ColorInM={theme.palette.stracker.contrastTextDark}
					/>
				</IconButton>
				<p style={{ color: theme.palette.stracker.contrastTextDark, marginTop: '10px' }}>{users.name}</p>
			</div>
			<div className="flex mr-8">
				{typeof users.battery === 'object' && !Array.isArray(users.battery) ? (
					<BatteryIcon ColorInM={theme.palette.stracker.contrastTextDark} />
				) : null}
				{users.geofence.length ? (
					<div style={{ marginLeft: '2px', marginRight: '-2px' }}>
						<MappinIcon ColorInM={theme.palette.stracker.contrastTextDark} />
					</div>
				) : null}
				{typeof users.temperature === 'object' && !Array.isArray(users.temperature) ? (
					<ThermometerIcon ColorInM={theme.palette.stracker.contrastTextDark} className="ml-5 mr-1" />
				) : null}
				{typeof users.humidity === 'object' && !Array.isArray(users.humidity) ? (
					<HumidityIcon ColorInM={theme.palette.stracker.contrastTextDark} className="ml-5 mt-1" />
				) : null}
			</div>
		</ListItemStyled>
	);
}

const ListItemStyled = styled(ListItem)`
	background-color: ${props => props.theme.palette.stracker.body};
	margin: 0 10px 10px 10px;
	border-radius: 8px;
	width: auto;
	padding: 0;
	box-shadow: ${props =>
		`${
			props.theme.palette.type === 'light'
				? '0px 4px 4px rgb(7 78 232 / 15%)'
				: '-5px -5px 8px #25292b, 5px 5px 8px #494f53'
		}`};
	span {
		font-weight: 600;
	}
`;

export default CardByPreset;
