import React, { useState } from 'react';
import ContractorsHeader from './ContractorsHeader';
import ContractorsContent from './ContractorsContent';
import withReducer from 'app/store/withReducer';
import reducer from '../store/index';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'contractors', fr);
i18next.addResourceBundle('en', 'contractors', en);

function Contractors({ displayMenuBurger, setBurgerIsOpen, burgerIsOpen, handleCloseDialogSettings }) {
	const [pageRouter, setPageRouter] = useState('');

	return (
		<div>
			<ContractorsHeader
				setPageRouter={setPageRouter}
				pageRouter={pageRouter}
				handleCloseDialogSettings={handleCloseDialogSettings}
				burgerIsOpen={burgerIsOpen}
				setBurgerIsOpen={setBurgerIsOpen}
				displayMenuBurger={displayMenuBurger}
			/>
			<ContractorsContent setPageRouter={setPageRouter} pageRouter={pageRouter} />
		</div>
	);
}

export default withReducer('members', reducer)(Contractors);
