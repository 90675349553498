import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Formsy from 'formsy-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import * as Actions from './store/actions';
import AddEmail from './inc/AddEmail';
import AddSms from './inc/AddSms';
import { ButtonStyled } from '../../../../../@stracker/components/StyledComponents/ButtonStyled';

function NotificationsContent(props) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { contrastTextLight } = theme.palette.stracker;
	const { t } = useTranslation('notifications');
	const formRef = useRef(null);

	const user = useSelector(({ auth }) => auth.user);
	const userId = user.id;
	const { idCustomer } = user.data;

	const notificationsEmail = useSelector(({ notifications }) => notifications.notifications.notificationsEmail);
	const notificationsSms = useSelector(({ notifications }) => notifications.notifications.notificationsSms);

	const [isFormValid, setIsFormValid] = useState(false);

	const [notifEmail, setNotifEmail] = useState([{ contactId: '', contactType: 'email' }]);
	const [notifSms, setNotifSms] = useState([{ contactId: '', contactType: 'sms' }]);

	React.useEffect(() => {
		dispatch(Actions.getNotificationsEmail(idCustomer));
		dispatch(Actions.getNotificationsSms(idCustomer));
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (notificationsEmail) {
			setNotifEmail(notificationsEmail);
		}
	}, [notificationsEmail]);

	useEffect(() => {
		if (notificationsSms) {
			setNotifSms(notificationsSms);
		}
	}, [notificationsSms]);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function handleSubmit() {
		const addList = notifEmail.concat(notifSms);

		dispatch(Actions.updateNotification(addList, idCustomer, userId));
	}

	return (
		<div>
			<div
				className="flex items-center mb-10 pb-10 border-b-1"
				style={{ borderColor: theme.palette.stracker.colorOutlined }}
			>
				<p id="message-form" style={{ color: contrastTextLight }}>
					{t('DIALOG_NOTIFICATION')}
				</p>
			</div>

			<h5 className="mb-5 text-16" style={{ color: contrastTextLight }} id="message-form">
				{t('NOTIFEMAIL')}
			</h5>
			<Formsy
				ref={formRef}
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				className="flex flex-col justify-center w-full"
			>
				<AddEmail hangleNotification={setNotifEmail} email={notifEmail} Custo={idCustomer} />
				<h5 className="mb-5 text-16" style={{ color: contrastTextLight }} id="message-form">
					{t('NOTIFSMS')}
				</h5>
				<AddSms hangleNotification={setNotifSms} sms={notifSms} Custo={idCustomer} />
				<div className="flex w-full justify-end mr-10">
					<ButtonStyled className="ml-5 py-7 px-10 mr-9" disabled={!isFormValid} type="submit">
						{t('CREATE')}
					</ButtonStyled>
				</div>
			</Formsy>
		</div>
	);
}

export default NotificationsContent;
