import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import List from '@material-ui/core/List';
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import * as Actions from './store/actions';
import BellIconAlerts from '../../../custom-icons/BellIconAlerts';
import CardByPreset from './CardByPreset.js';
import { ButtonStyled } from '@stracker/components/StyledComponents/ButtonStyled';

function AlertsContent({ heightHeader, setDisplayAddAlert, setAlertsData }) {
	const theme = useTheme();
	const { t } = useTranslation('alerts');
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);

	const handleClickOpenDialog = () => {
		setOpen(true);
	};
	const handleCloseDialog = () => {
		setPreset(null);
		setOpen(false);
	};

	const [preset, setPreset] = React.useState(null);

	const user = useSelector(({ auth }) => auth.user);
	const idUser = user.id;
	const { idCustomer } = user.data;

	const alertsFav = useSelector(({ alerts }) => alerts.alerts.alertsFav);

	React.useEffect(() => {
		dispatch(Actions.getAlerts(user.id, idCustomer));
		// eslint-disable-next-line
	}, [dispatch]);

	function printDialog(idpresets) {
		setPreset(idpresets);
		handleClickOpenDialog();
	}

	function deletePresets() {
		const model = {};
		model.idAlert = preset;
		model.idUser = idUser;
		model.idCustomer = idCustomer;

		dispatch(Actions.deleteAlert(model));
		setOpen(false);
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle style={{ color: theme.palette.stracker.contrastTextDark }} id="alert-dialog-title">
					Supprimer le preset
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						style={{ color: theme.palette.stracker.contrastTextDark }}
						id="alert-dialog-description"
						className="mb-0 font-semibold"
					>
						{t('CONFIRM_DELETE_PRESET')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ButtonStyled type="button" className="py-7 px-10 ml-5" onClick={handleCloseDialog} secondary>
						{t('BOUTON_CANCEL')}
					</ButtonStyled>
					<ButtonStyled type="button" className="py-7 px-10 ml-5" onClick={() => deletePresets()}>
						{t('BOUTON_VALIDER')}
					</ButtonStyled>
				</DialogActions>
			</Dialog>
			<div
				className="overflow-hidden py-10 px-5 rounded-lg"
				style={{
					backgroundColor: theme.palette.background.body,
					height: `calc(100% - ${heightHeader + 10}px)`
				}}
			>
				<div className="flex items-center mb-10 ml-10">
					<BellIconAlerts ColorInM={theme.palette.stracker.contrastTextDark} />
					<p>{t('FAV_ALERT_LIST')}</p>
				</div>
				<FuseScrollbars className="overflow-scroll w-full h-full">
					<List className="w-full" disablePadding>
						{alertsFav &&
							alertsFav.length > 0 &&
							alertsFav.map(users => {
								return (
									<CardByPreset
										users={users}
										printDialog={printDialog}
										handleChange={''}
										typePreset="Alerts"
										heightHeader={heightHeader}
										setDisplayAddAlert={setDisplayAddAlert}
										setAlertsData={setAlertsData}
									/>
								);
							})}
					</List>
				</FuseScrollbars>
			</div>
		</>
	);
}

export default AlertsContent;
