import MomentUtils from '@date-io/moment';
import '@fake-db';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import { useLoadScripts } from '@stracker/hooks'; // PLS - 18/10/2021 : import new stracker use hook
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';


const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

 // PLS - 18/10/2021 : Google MAP API Link + STRACKER Key
const Gkey = process.env.REACT_APP_GOOGLE_KEY;
const GMapUrl = `https://maps.googleapis.com/maps/api/js?key=${Gkey}&libraries=places&language=en`;

const App = () => { 

	useLoadScripts(GMapUrl, true, true);
	/*  PLS - 18/10/2021 : Call Google API Link and Append in index.html body on page load
	React.useEffect(() => {
		const script = document.createElement('script');
	  
		script.src = `https://maps.googleapis.com/maps/api/js?key=${Gkey}&libraries=places&language=en`; 
		script.async = true;
		script.defer = true;
	  
		document.body.appendChild(script);
	  
		return () => {
		  document.body.removeChild(script);
		}
	  }, []); */

	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Auth>
							<Router history={history}>
								<FuseAuthorization>
									<FuseTheme>
										<FuseLayout />
									</FuseTheme>
								</FuseAuthorization>
							</Router>
						</Auth>
					</MuiPickersUtilsProvider>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
