import React, { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import CircleAddIconBlue from '@stracker/customicons/CircleAddIconBlue';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import DialogPingMap from '@stracker/components/Dialogs/DialogPingMap';
import CrossHair from '@stracker/customicons/CrossHair';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GeocodingStrackerGoogleMap from '../../../../apps/transport/customComponents/GeocodingStrackerGoogleMap.js';

function RouteStep({ allAlerts, setAllAlerts }) {
	const useStyles = makeStyles(theme => ({
		font1: { color: theme.palette.stracker.contrastTextDark, fontWeight: '600' }
	}));

	const theme = useTheme();
	const classes = useStyles();
	const { t } = useTranslation('alerts');
	const isMobileDevice = useMediaQuery('(max-width:960px)');

	const [globalCode, setGlobalCode] = useState({
		transit: []
	});
	const [currentDialogPing, setCurrentDialogPing] = useState(null);
	const [openDialogPingMap, setOpenDialogPingMap] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const deleteStep = index => {
		allAlerts.geofence.splice(index, 1);
		const copyGlobalCode = globalCode;
		copyGlobalCode.transit.splice(index, 1);
		setGlobalCode(copyGlobalCode);
		setRefresh(!refresh);
	};

	const handleOpenDialogMapPing = e => {
		e.stopPropagation();
		setOpenDialogPingMap(true);
		setCurrentDialogPing(e.target.name);
	};

	const handleCloseDialogMapPing = () => {
		setOpenDialogPingMap(false);
	};

	const generateCoordinate = typeCoordinate => {
		if (!currentDialogPing) {
			if (typeCoordinate === 'lat') return null;
			if (typeCoordinate === 'lng') return null;
		}
		if (!currentDialogPing.includes('step')) {
			if (typeCoordinate === 'lat') {
				return globalCode[currentDialogPing].coordinate.lat;
			}
			return globalCode[currentDialogPing].coordinate.lng;
		}
		if (currentDialogPing.includes('step')) {
			const stepNumber = currentDialogPing.replace('step', '');
			const parseStepNumber = parseInt(stepNumber, 10);
			if (!globalCode.transit[parseStepNumber - 1]) return null;

			if (typeCoordinate === 'lat') {
				return globalCode.transit[parseStepNumber - 1].coordinate.lat;
			}
			return globalCode.transit[parseStepNumber - 1].coordinate.lng;
		}
	};

	const validAdresseTransit = (address, index) => {
		allAlerts.geofence[index].fullAddress = address.fullAddress;
		allAlerts.geofence[index].latitude = address.latitude;
		allAlerts.geofence[index].longitude = address.longitude;
		allAlerts.geofence[index].country = address.address.country ? address.address.country : '';
		allAlerts.geofence[index].city = address.address.city ? address.address.city : '';
		allAlerts.geofence[index].street = address.address.street ? address.address.street : '';
		allAlerts.geofence[index].tripNumber = index + 1;
		allAlerts.geofence[index].show = true;
	};

	const addStep = () => {
		const allAlertCpy = { ...allAlerts };
		allAlertCpy.geofence.push({
			fullAddress: null,
			latitude: null,
			longitude: null,
			country: null,
			city: null,
			street: null,
			tripNumber: null,
			direction: 'INOUT',
			gpsLocation: null,
			min: 5,
			name: 'geofencing',
			IN: true,
			OUT: true,
			show: null
		});
		setAllAlerts(allAlertCpy);
	};

	return (
		<div>
			{allAlerts.geofence.map(
				(data, index) =>
					index > 1 && (
						<div key={index} name="transit">
							<div className="flex justify-between">
								<p
									style={{
										marginLeft: '4px',
										fontSize: '12px',
										color: theme.palette.stracker.contrastTextLight
									}}
									variant="body1"
								>
									{t('STEP')} {index - 1}
								</p>
							</div>
							<DialogPingMap
								openDialogPingMap={openDialogPingMap}
								handleCloseDialogMapPing={handleCloseDialogMapPing}
								setGlobalCode={setGlobalCode}
								globalCode={globalCode}
								currentDialogPing={currentDialogPing}
								latState={generateCoordinate('lat')}
								lngState={generateCoordinate('lng')}
								getUpdateAddressTransit={validAdresseTransit}
								isMobileDevice={isMobileDevice}
								t={t}
								geofenceTransport={data.geofence}
								dataCreatePresetAlert={data}
								page="createPresetAlert"
							/>
							<div className="mt-10 flex items-center">
								<Tooltip title={t('DELETE_A_STEP')}>
									<IconButton
										id={index + 1}
										size="small"
										onClick={ev => deleteStep(index)}
										style={{ marginRight: '7px', marginBottom: '6px' }}
									>
										<CircleDeleteIcon />
									</IconButton>
								</Tooltip>
								<GeocodingStrackerGoogleMap
									address={data.fullAddress}
									id={index}
									getUpdateAddress={validAdresseTransit}
									height="40px"
									step={`step${index + 1}`}
									globalCode={globalCode}
									setGlobalCode={setGlobalCode}
									currentDialogPing={currentDialogPing}
									setCurrentDialogPing={setCurrentDialogPing}
									dataCreatePresetAlert={data}
									page="createPresetAlert"
								/>
								<button
									type="button"
									className="ml-10 mb-10"
									name={`step${index + 1}`}
									onClick={e => handleOpenDialogMapPing(e)}
								>
									<CrossHair className="pointer-events-none" size={20} />
								</button>
							</div>
						</div>
					)
			)}
			<div className="flex items-center" style={{ marginTop: '5px', marginBottom: '5px' }}>
				<IconButton size="small" onClick={e => addStep()}>
					<CircleAddIconBlue />
				</IconButton>
				<p className={clsx(classes.font1, 'ml-4')}>{t('ADDSTEP')}</p>
			</div>
		</div>
	);
}

export default RouteStep;
