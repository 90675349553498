import React, { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import MappinIcon from '@stracker/customicons/MappinIcon';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import IncrementDecrement from '@stracker/components/IncrementDecrement';
import DialogPingMap from '@stracker/components/Dialogs/DialogPingMap';
import CrossHair from '@stracker/customicons/CrossHair';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GeocodingStrackerGoogleMap from '../../../../apps/transport/customComponents/GeocodingStrackerGoogleMap.js';
import RouteStep from './RouteStep.js';

function Route({ allAlerts, setAllAlerts }) {
	const useStyles = makeStyles(theme => ({
		font1: { color: theme.palette.stracker.contrastTextDark, fontWeight: '600' }
	}));

	const theme = useTheme();
	const classes = useStyles();
	const { t } = useTranslation('alerts');
	const isMobileDevice = useMediaQuery('(max-width:960px)');

	const [globalCode, setGlobalCode] = useState({
		transit: []
	});
	const [currentDialogPing, setCurrentDialogPing] = useState(null);
	const [openDialogPingMap, setOpenDialogPingMap] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const deleteStep = index => {
		const allAlertCpy = { ...allAlerts };
		allAlertCpy.geofence = [];
		setAllAlerts(allAlertCpy);
		const copyGlobalCode = globalCode;
		copyGlobalCode.transit = [];
		setGlobalCode(copyGlobalCode);
		setRefresh(!refresh);
	};

	const handleChange = (ev, index) => {
		if (ev !== 'decrement' && ev !== 'increment') allAlerts.geofence[index].min = parseInt(ev);
		else if (ev === 'decrement' && allAlerts.geofence[index].min > 1) allAlerts.geofence[index].min -= 1;
		else if (ev === 'increment') allAlerts.geofence[index].min += 1;
		setRefresh(!refresh);
	};

	const handleOpenDialogMapPing = e => {
		e.stopPropagation();
		setOpenDialogPingMap(true);
		setCurrentDialogPing(e.target.name);
	};

	const handleCloseDialogMapPing = () => {
		setOpenDialogPingMap(false);
	};

	const generateCoordinate = typeCoordinate => {
		if (!currentDialogPing) {
			if (typeCoordinate === 'lat') return null;
			if (typeCoordinate === 'lng') return null;
		}
		if (!currentDialogPing.includes('step')) {
			if (typeCoordinate === 'lat') {
				return globalCode[currentDialogPing].coordinate.lat;
			}
			return globalCode[currentDialogPing].coordinate.lng;
		}
		if (currentDialogPing.includes('step')) {
			const stepNumber = currentDialogPing.replace('step', '');
			const parseStepNumber = parseInt(stepNumber, 10);
			if (!globalCode.transit[parseStepNumber - 1]) return null;

			if (typeCoordinate === 'lat') {
				return globalCode.transit[parseStepNumber - 1].coordinate.lat;
			}
			return globalCode.transit[parseStepNumber - 1].coordinate.lng;
		}
	};

	const validAdresseTransit = (address, index) => {
		allAlerts.geofence[index].fullAddress = address.fullAddress;
		allAlerts.geofence[index].latitude = address.latitude;
		allAlerts.geofence[index].longitude = address.longitude;
		allAlerts.geofence[index].country = address.address.country ? address.address.country : '';
		allAlerts.geofence[index].city = address.address.city ? address.address.city : '';
		allAlerts.geofence[index].street = address.address.street ? address.address.street : '';
		allAlerts.geofence[index].tripNumber = index + 1;
		allAlerts.geofence[index].show = true;
	};

	if (allAlerts.geofence[0]) {
		return (
			<div
				key={0}
				name="transit"
				style={{
					paddingLeft: '7px',
					marginTop: '5px',
					paddingTop: '5px',
					paddingBottom: '20px',
					borderTop: 'solid 1px',
					borderBottom: 'solid 1px',
					borderColor: theme.palette.stracker.colorOutlined
				}}
			>
				<div className="flex justify-between">
					<div className="flex flex-row items-center">
						<Tooltip title={t('DELETE_A_STEP')}>
							<IconButton
								id={0 + 1}
								size="small"
								onClick={ev => deleteStep(0)}
								style={{ marginRight: '7px' }}
							>
								<CircleDeleteIcon />
							</IconButton>
						</Tooltip>
						<MappinIcon />
						<p className={clsx(classes.font1, 'ml-6 text-16')}>{t('MAP')}</p>
					</div>
					<div>
						<IncrementDecrement
							handleChange={handleChange}
							value={allAlerts.geofence[0].min}
							valueUnits={'km'}
							title={t('GEOFENCE')}
							type={0}
						/>
					</div>
				</div>
				<div className="flex justify-between">
					<div>
						<p
							style={{
								marginLeft: '4px',
								fontSize: '12px',
								color: theme.palette.stracker.contrastTextLight
							}}
							variant="body1"
						>
							D
						</p>
					</div>
				</div>

				<DialogPingMap
					openDialogPingMap={openDialogPingMap}
					handleCloseDialogMapPing={handleCloseDialogMapPing}
					setGlobalCode={setGlobalCode}
					globalCode={globalCode}
					currentDialogPing={currentDialogPing}
					latState={generateCoordinate('lat')}
					lngState={generateCoordinate('lng')}
					getUpdateAddressTransit={validAdresseTransit}
					isMobileDevice={isMobileDevice}
					t={t}
					geofenceTransport={allAlerts.geofence[0].min}
					dataCreatePresetAlert={allAlerts.geofence[0]}
					page="createPresetAlert"
				/>
				<div className="mt-10 flex items-center">
					<GeocodingStrackerGoogleMap
						address={allAlerts.geofence[0].fullAddress}
						id={0}
						getUpdateAddress={validAdresseTransit}
						height="40px"
						step={`step${1}`}
						globalCode={globalCode}
						setGlobalCode={setGlobalCode}
						currentDialogPing={currentDialogPing}
						setCurrentDialogPing={setCurrentDialogPing}
						dataCreatePresetAlert={allAlerts.geofence[0]}
						page="createPresetAlert"
					/>
					<button
						type="button"
						className="ml-10 mb-10"
						name={t('DEPARTURE')}
						onClick={e => handleOpenDialogMapPing(e)}
					>
						<CrossHair className="pointer-events-none" size={20} />
					</button>
				</div>
				<RouteStep allAlerts={allAlerts} setAllAlerts={setAllAlerts} />
				<div className="flex justify-between">
					<div>
						<p
							style={{
								marginLeft: '4px',
								fontSize: '12px',
								color: theme.palette.stracker.contrastTextLight
							}}
							variant="body1"
						>
							A
						</p>
					</div>
				</div>

				<DialogPingMap
					openDialogPingMap={openDialogPingMap}
					handleCloseDialogMapPing={handleCloseDialogMapPing}
					setGlobalCode={setGlobalCode}
					globalCode={globalCode}
					currentDialogPing={currentDialogPing}
					latState={generateCoordinate('lat')}
					lngState={generateCoordinate('lng')}
					getUpdateAddressTransit={validAdresseTransit}
					isMobileDevice={isMobileDevice}
					t={t}
					geofenceTransport={allAlerts.geofence[0].min}
					dataCreatePresetAlert={allAlerts.geofence[1]}
					page="createPresetAlert"
				/>
				<div className="mt-10 flex items-center">
					<GeocodingStrackerGoogleMap
						address={allAlerts.geofence[1].fullAddress}
						id={1}
						getUpdateAddress={validAdresseTransit}
						height="40px"
						step={`step${2}`}
						globalCode={globalCode}
						setGlobalCode={setGlobalCode}
						currentDialogPing={currentDialogPing}
						setCurrentDialogPing={setCurrentDialogPing}
						dataCreatePresetAlert={allAlerts.geofence[1]}
						page="createPresetAlert"
					/>
					<button
						type="button"
						className="ml-10 mb-10"
						name={t('ARRIVAL')}
						onClick={e => handleOpenDialogMapPing(e)}
					>
						<CrossHair className="pointer-events-none" size={20} />
					</button>
				</div>
			</div>
		);
	} else return null;
}

export default Route;
