const locale = {
	ADD_CONTRACTORS: 'Add contractor',
	CREATE: 'Validate',
	CANCEL: 'Cancel',
	UPDATE: 'Validate',
	PLACEHOLDER_SEARCH: 'Search',
	CONTRACTORS: 'Contractors',
	MODIFYCONTRACTOR: 'Modify Contractor',
	IDENTITY: 'Identity',
	EMAIL: 'Email',
	PHONE: 'Phone number',
	ROLE: 'Role',
	CONTRACTORMODIF: 'Modify',
	CONTRACTORSUPPR: 'Delete',
	CONFIRM_DELETE_CONTRACTOR: 'Do you really want to delete this contractor ?',
	DELETE: 'Delete'
};

export default locale;
