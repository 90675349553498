import React from 'react';

function BellIconAlerts(props) {
	return (
		<svg
			className="float-left mr-10"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.5 6.38599C13.5 5.19251 13.0259 4.04792 12.182 3.20401C11.3381 2.36009 10.1935 1.88599 9 1.88599C7.80653 1.88599 6.66193 2.36009 5.81802 3.20401C4.97411 4.04792 4.5 5.19251 4.5 6.38599C4.5 11.636 2.25 13.136 2.25 13.136H15.75C15.75 13.136 13.5 11.636 13.5 6.38599Z"
				stroke={props.ColorInM}//"#023E65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.2974 16.136C10.1655 16.3633 9.97628 16.552 9.74856 16.6831C9.52085 16.8143 9.26268 16.8833 8.99989 16.8833C8.73711 16.8833 8.47894 16.8143 8.25122 16.6831C8.02351 16.552 7.83425 16.3633 7.70239 16.136"
				stroke={props.ColorInM}//"#023E65
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<defs>
				<clipPath id="clip0">
					<rect width="18" height="18" fill="white" transform="translate(0 0.385986)" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default BellIconAlerts;
