const locale = {
	TITLE: 'Customers',
	NOCLIENT: 'No customer registered',
	ADDCLIENT: 'Add a Customer',
	ADDUSER: 'Add a user',
	NEWCLIENT: 'Creation of a customer',
	PAUSECLIENT: 'Pause',
	ACTIVECLIENT: 'Activate the customer',
	DELETECLIENT: 'Delete the customer',
	EDITCLIENT: 'Edit the customer',
	PAUSEUSER: 'Pause',
	ACTIVEUSER: 'Activate user',
	DELETEUSER: 'Delete user',
	EDITUSER: 'Edit user',
	USERS: '#Users',
	SAVEUSER: 'Save',
	TRACKERS: 'Trackers',
	VOIRPLUS: 'See more',
	SEARCHHEADER: 'Search',
	CATEGORY: 'Category',
	CANCEL: 'Cancel',
	AGREE: 'Save',
	PAUSECLIENTTITLE: 'Are you sure you want to pause the customer ',
	ACTIVECLIENTTITLE: 'Are you sure you want to activate the customer ',
	DELETECLIENTTITLE: 'Are you sure you want to delete the customer ',
	PAUSECLIENTDIALOG:
		'This action will pause the customer. He will no longer have access to www.stracker360.com, nor to its notifications.',
	ACTIVECLIENTDIALOG: "Would you like to reactivate this customer's account?",
	DELETECLIENTDIALOG:
		'This action will result in the permanent deletion of the customer account. All of its users will no longer have access to www.stracker360.com, nor to their notifications.',
	PAUSEUSERTITLE: 'Are you sure you want to pause this user',
	ACTIVEUSERTITLE: 'Are you sure you want to activate this user',
	DELETEUSERTITLE: 'Are you sure you want to delete this user',
	PAUSEUSERDIALOG:
		'This action will result in the suspension of the customer account. All of its users will no longer have access to www.stracker360.com, nor to their notifications, until the account is reactivated.',
	ACTIVEUSERDIALOG:
		'This action will result in the reactivation of the user account. He will have access to www.stracker360.com, and its notifications.',
	DELETEUSERDIALOG:
		'This action will result in the permanent deletion of the user account. He will no longer have access to www.stracker360.com, nor to its notifications.',
	ENDSCROLL: 'Last reached',
	LOADING: 'Loading',
	ADDNEWFILTER: 'Filters',
	ORDERBY: 'Sort by',
	NEWUSER: 'Create a user',
	ADDUSERS: 'Add users',
	CUSTOMERNAMEAPPEAR: 'Name will appear on the dashboard',
	SOCIETE: 'Name of the society',
	RESPONSABLE: 'Company manager',
	LASTNAME: 'Name',
	FIRSTNAME: 'Surname',
	EMAIL: 'Email',
	ACTIVITY: 'Main sector',
	USER: 'User',
	SOCIETY_NAME: 'Name of the society',
	LOGO: 'Logo',
	SUBSCRIPTION: 'Subscription',
	YES: 'Yes',
	NO: 'No',
	SELECT_URL: 'Insert url of the logo',
	PREVIEW: 'Preview',
	NEXT: 'Suivant'
};

export default locale;
