import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function MinusIcon(props) {
	const theme = useTheme();
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.75 9H14.25"
				stroke={theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default MinusIcon;
