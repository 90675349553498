const locale = {
	BTN_READ_ALL_NOTIFICATIONS_TEXT: 'Mark all as read',
	BTN_READ_NOTIFICATION_TEXT: 'Mark as read',
	PLACEHOLDER_SEARCH: 'Search for',
	TITLE: 'Notification',
	DIALOG_NOTIFICATION: 'Set-up the notifications applicable to all alerts related to your customer account.',
	READ_FILTER: 'Read',
	HIDE_FILTERS: 'Hide filters',
	UNREAD_FILTER: 'Unread',
	SHOW_FILTERS: 'Show filters',
	STATUS_FILTER: 'Status',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest'
};

export default locale;
