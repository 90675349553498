const locale = {
	APPLICATIONS: 'Applications',
	EXAMPLE: 'Exemple',
	DASHBOARD: 'Tableau de bord',
	TRANSPORT: 'Transports',
	ASSETS: 'Actifs',
	TRACKING: 'recherche colis Intérieur',
	DEVICES: 'Trackers',
	CLIENT: 'Gestion client',
	SEARCH: 'Rechercher',
	CONTENT: 'Contenus',
	HISTORY: 'Historique',
	MASTER_ASSET: 'Contenants',
	COTATIONS: 'Cotations',
	ANALYTICS: 'Analytique',
	TASKS: 'Tâches',
	FASHIONHUB: 'Fashion Hub'
};

export default locale;
