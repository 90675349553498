import { combineReducers } from 'redux';
import contractor from './contractors.reducer';
import member from './membres.reducer';

const reducer = combineReducers({
	contractor,
	member
});

export default reducer;
