import React from 'react';

function AlertTriangle(props) {
	return (
		<div className={props.noclass ? "" : "float-left mr-10"}>
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.71758 2.89503L1.36508 13.5C1.2341 13.7268 1.1648 13.984 1.16407 14.2459C1.16334 14.5078 1.23119 14.7654 1.3609 14.9929C1.4906 15.2205 1.67762 15.4101 1.90336 15.5429C2.12909 15.6757 2.38568 15.7472 2.64758 15.75H15.3526C15.6145 15.7472 15.8711 15.6757 16.0968 15.5429C16.3225 15.4101 16.5096 15.2205 16.6393 14.9929C16.769 14.7654 16.8368 14.5078 16.8361 14.2459C16.8354 13.984 16.7661 13.7268 16.6351 13.5L10.2826 2.89503C10.1489 2.67461 9.96062 2.49237 9.73597 2.36589C9.51133 2.23942 9.25788 2.17297 9.00008 2.17297C8.74228 2.17297 8.48883 2.23942 8.26418 2.36589C8.03954 2.49237 7.85128 2.67461 7.71758 2.89503V2.89503Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9 6.75V9.75"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9 12.75H9.0075"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default AlertTriangle;
