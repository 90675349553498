import i18next from 'i18next';
import en from './navigation-i18n/en';
import fr from './navigation-i18n/fr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('fr', 'navigation', fr);

const navigationConfig = [
	// {
	// 	id: 'dashboard',
	// 	title: 'Dashboard',
	// 	translate: 'DASHBOARD',
	// 	type: 'item',
	// 	icon: 'home',
	// 	url: '/dashboards'
	// },
	{
		id: 'cotations',
		title: 'Cotations',
		translate: 'COTATIONS',
		type: 'item',
		icon: 'cotation',
		url: '/cotations',
		target: '_blank'
	},
	{
		id: 'fashionhub',
		title: 'Fashionhub',
		translate: 'FASHIONHUB',
		type: 'item',
		icon: 'fashionhub',
		url: '/fashionhub'
	},
	{
		id: 'transport',
		title: 'Transport',
		translate: 'TRANSPORT',
		type: 'item',
		icon: 'transport',
		url: '/transport'
	},
	{
		id: 'client',
		title: 'Gestion client',
		translate: 'CLIENT',
		type: 'item',
		icon: 'client',
		url: '/clients'
	},
	{
		id: 'history',
		title: 'History',
		translate: 'HISTORY',
		type: 'item',
		icon: 'history',
		url: '/history'
	}
];

export default navigationConfig;
