import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import FuseAnimate from '@fuse/core/FuseAnimate';
import FuseUtils from '@fuse/utils';
import clsx from 'clsx';
import _ from '@lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import * as Actions from './store/actions';

import MoreBtn from './inc/MoreBtn';
import RoleSelect from './inc/RoleSelect';

import AddMembers from './AddMembers';
import EditMembers from './EditMembers';
import SearchStyled from '../../../../../@stracker/components/StyledComponents/SearchStyled';

function MembresContent(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('membres');
	const theme = useTheme();
	const { contrastTextLight } = theme.palette.stracker;

	const user = useSelector(({ auth }) => auth.user);

	const memberList = useSelector(({ members }) => members.member.state);
	const searchText = useSelector(({ members }) => members.member.searchText);

	const [valueSwitch, setValueSwitch] = useState('');
	const [hideList, setHideList] = useState('');
	const [idMember, setIdMember] = useState(null);

	const [filteredData, setFilteredData] = useState(null);
	const [isError, setIsError] = useState(false);
	const [colorItem, setColorItem] = useState('classRed');
	const [isErrorMsg, setIsErrorMsg] = useState('');
	const [isErrorMsgEmail, setIsErrorMsgEmail] = useState('');

	const boutonCancel = () => {
		setIsError(false);
		setIdMember(0);
		setValueSwitch('');
		setHideList('');
		setIsErrorMsgEmail('');
		props.switchPageAdd('');
	};

	useEffect(() => {
		if (user.uuid) {
			const membersList = {
				idCustomer: user.data.idCustomer,
				position: 1,
				limit: 300
			};
			dispatch(Actions.getMembres(membersList));
		}
	}, [dispatch, user]);

	useEffect(() => {
		function getFilteredArray() {
			const arr = Object.keys(memberList).map(id => memberList[id]);
			if (searchText.length === 0) {
				return arr;
			}
			return FuseUtils.filterArrayByString(arr, searchText);
		}

		if (memberList) {
			setFilteredData(getFilteredArray());
		}
	}, [memberList, searchText]);

	useEffect(() => {
		if (props.AddMem === 'AddMembers') {
			setValueSwitch('AddMembers');
			setHideList('hidden');
			setIsErrorMsgEmail('');
		}
	}, [props.AddMem]);

	function handlePage(id, comp) {
		setIdMember(id);
		setValueSwitch(comp);
		setHideList('hidden');
	}

	function displayMSG(colorI, mMSG) {
		setIsError(true);
		setColorItem(colorI);
		setIsErrorMsg(mMSG);
	}

	function eraseMSG() {
		setIsError(false);
		setIsErrorMsgEmail('');
	}

	function SwitchCase(page) {
		switch (page.value) {
			case 'AddMembers':
				return <AddMembers switchPage={handlePage} cancel={boutonCancel} />;
			case 'EditMembers':
				return <EditMembers idEditMember={idMember} switchPage={handlePage} cancel={boutonCancel} />;

			default:
				return '';
		}
	}

	if (!filteredData) {
		return null;
	}

	return (
		<>
			<div className={clsx(hideList)} style={{ height: 'calc(100% - 90px)' }}>
				<div
					className="flex justify-between items-center border-b-1 border-t-1 py-10"
					style={{ borderColor: theme.palette.stracker.colorOutlined }}
				>
					<p style={{ color: contrastTextLight, marginRight: '10px' }}>{t('MEMBERS')}</p>
					<SearchStyled
						searchValue={searchText}
						setSearchValue={e => dispatch(Actions.setSearchText(e))}
						placeholder={`${t('PLACEHOLDER_SEARCH')}...`}
					/>
				</div>

				{isError && (
					<div>
						<Typography
							className={clsx(colorItem, 'text-center font-semibold mt-10 mb-10 ')}
							id="message-form"
						>
							{t(isErrorMsg)} <br /> {isErrorMsgEmail}
						</Typography>
					</div>
				)}

				{filteredData &&
					(filteredData.length > 0 ? (
						<ListStyled aria-label="main members List">
							{filteredData.map((users, index) => {
								let firstname = '';
								let lastname = '';
								if (users.firstName === null) {
									firstname = '';
								} else {
									firstname = users.firstName;
								}
								if (users.lastName === null) {
									lastname = '';
								} else {
									lastname = users.lastName;
								}

								const displayNam = `${users.firstName} ${users.lastName}`;

								const initiales =
									!_.isEmpty(displayNam) && !_.isNull(displayNam)
										? displayNam.match(/\b\w/g).join('').toUpperCase()
										: '';

								return (
									<ListItem
										className="my-5 border-b-1"
										style={{ borderColor: theme.palette.stracker.colorOutlined }}
										key={users.idUser}
										alignItems="center"
										disableGutters
									>
										<ListItemAvatar>
											<AvatarStyled
												isadmin={users.roleName !== 'user' ? 'true' : 'false'}
												size={45}
												alt={`${firstname} ${lastname}`}
											>
												{initiales}
											</AvatarStyled>
										</ListItemAvatar>
										<ListItemText
											primary={
												<span className="font-semibold">
													{firstname} {lastname}
												</span>
											}
											secondary={
												<span
													className="font-semibold text-12"
													style={{ color: contrastTextLight }}
												>
													<span>{users.email}</span>
													<br />
													<span>{users.phone}</span>
												</span>
											}
										/>

										<ListItemSecondaryAction key={index} className="flex justify-end">
											<RoleSelect userId={users.idUser} roleId={users.idRole} />
											{user.role !== 'user' && (
												<MoreBtn
													displayM={displayMSG}
													eraseM={eraseMSG}
													switchPage={handlePage}
													userId={users.idUser}
													roleId={users.idRole}
												/>
											)}
										</ListItemSecondaryAction>
									</ListItem>
								);
							})}
						</ListStyled>
					) : (
						<FuseAnimate delay={100}>
							<div className="flex flex-1 items-center justify-center h-full">
								<Typography color="textSecondary" variant="h5" className="text-24 my-24">
									{t('NO_MESSAGES')}
								</Typography>
							</div>
						</FuseAnimate>
					))}
			</div>

			<SwitchCase value={valueSwitch} />
		</>
	);
}

const ListStyled = styled(List)`
	height: calc(100% - 75px);
	overflow: auto;
	padding-top: 0;
	margin-top: 10px;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border: 2px solid ${props => props.theme.palette.stracker.colorOutlined};
		box-shadow: none;
	}
`;

const AvatarStyled = styled(Avatar)`
	width: ${props => (props.size ? props.size : 38)}px !important;
	height: ${props => (props.size ? props.size : 38)}px !important;
	color: ${props => (props.isadmin === 'true' ? props.theme.palette.stracker.contrastText : '#eda124')};
	border: 2px solid #fff;
	font-size: 1.5rem;
	box-shadow: 0px 4px 4px rgb(7 78 232 / 15%);
	font-weight: 600;
	background-color: ${props => (props.isadmin === 'true' ? '#A8C1F7' : '#FAE3BD')};
	margin-right: 13px;
	margin-bottom: 0 !important;
`;

export default MembresContent;
