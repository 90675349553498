const locale = {
	TITLE: 'Example Page',
	TITLEMEMBRE: 'Members',
	NO_MESSAGES: 'No members to display',
	MEMBERS: 'Members',
	ACCESS: 'Access',
	ADDMEMBER: 'Add a member',
	MODIFMEMBER: 'Update member',
	FIRSTNAME: 'Firstname',
	NAME: 'Nom',
	TEL: 'Phone',
	EMAIL: 'Email',
	UPDATE: 'Validate',
	CANCEL: 'Cancel',
	MEMBMODIF: 'Modify user',
	MEMBSUPPR: 'Delete user',
	CONFIRM_DELETE_USER: 'Confirm delete',
	BOUTON_VALIDER: 'Validate',
	BOUTON_CANCEL: 'Cancel',
	ADDUSE: 'Add member',
	ADDUSERS: 'Add members',
	EMAILNOVALID: 'This is not a valid email',
	CREATE: 'Validate',
	UPDACNTSUC: 'Member successfully modified',
	UPDACNTERR: 'Failed to edit',
	CREATESUCC: 'Member(s) successfully created',
	CREATEERR: 'Member already exists',
	DELETESUCC: 'Successful deletion',
	DELETEERR: 'Error during deletion',
	FISRTPMSG: 'Creation error, the following address (es) already exist :',
	REQUIRED: 'Required',
	PLACEHOLDER_SEARCH: 'Search'
};

export default locale;
