//import axios from 'axios';
import API from '@pi/api';

export const CREATE_ALERT_ERROR = 'CREATE_ALERT_ERROR';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';

export const UPDATE_ALERT_ERROR = 'UPDATE_ALERT_ERROR';
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';

export const DELETE_ALERT_ERROR = 'DELETE_ALERT_ERROR';
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';

export const GET_SC_ALERTS = '[SUPPLY CHAIN ALERTS GEOFENCING] GET ALERTS';

export const GET_SC_ALERTS_MYALERT = '[SUPPLY CHAIN ALERTS MYALERT] GET ALERTS MYALERT';

export function getAlerts(idUser, idCustomer) {
	const request = API.post('/alert', {
		action: 'list',
		idCustomer,
		idUser
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_ALERTS,
				payload: response.data
			})
		);
}

export function createAlert(model) {
	const { type, name, value, idUser, direction, address, location, min, max, idCustomer } = model;
	const request = API.post('/alert', {
		action: 'create',
		type,
		name,
		value,
		idUser,
		direction,
		address,
		location,
		min,
		max,
		idCustomer
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: CREATE_ALERT_SUCCESS,
				payload: response.data
			})
		);
}

export function updateAlert(json) {
	const request = API.post('/alert', {
		action: 'update',
		idUser: json.idUser,
		idCustomer: json.idCustomer,
		id: json.id,
		name: json.name,
		temperature: json.temperature.display === true ? json.temperature : null,
		humidity: json.humidity.display === true ? json.humidity : null,
		battery: json.battery.display === true ? json.battery : null,
		geofence: json.geofence ? json.geofence : null
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: UPDATE_ALERT_SUCCESS,
				payload: response.data
			})
		);
}

export function deleteAlert(model) {
	const { idAlert, idUser, idCustomer } = model;
	const request = API.post('/alert', {
		action: 'delete',
		group: idAlert,
		idCustomer,
		idUser
	});

	return dispatch =>
		request
			.then(response =>
				dispatch({
					type: DELETE_ALERT_SUCCESS,
					payload: response.data
				})
			)
			.then(() => dispatch(getAlerts(idUser, idCustomer)));
}

export function getAlertsMyalert(idAlert, idUser) {
	const request = API.post('/alert', {
		action: 'read',
		idAlert,
		idUser
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_ALERTS_MYALERT,
				payload: response.data
			})
		);
}
