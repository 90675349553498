import React from 'react';
import BatteryIcon from '@stracker/customicons/BatteryIcon';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IncrementDecrement from '@stracker/components/IncrementDecrement';

function Battery({ allAlerts, setAllAlerts }) {
	const handleClose = () => {
		const allAlertCpy = { ...allAlerts };
		allAlertCpy.battery.display = false;
		setAllAlerts(allAlertCpy);
	};

	const handleChange = (ev, type, title) => {
		let value = allAlerts.battery.min;
		if (type === 'battery') {
			if (ev === 'decrement') value -= 1;
			else if (ev === 'increment') value += 1;
			else if (ev !== 'decrement' && ev !== 'increment') value = ev;
		}
		const allAlertCpy = { ...allAlerts };
		allAlertCpy.battery.min = value;
		setAllAlerts(allAlertCpy);
	};

	return (
		<div
			className="flex"
			style={{
				paddingLeft: '10px'
			}}
		>
			<ListItem
				role={undefined}
				disableGutters
				button
				style={{
					width: 'auto',
					paddingTop: '20px',
					paddingBottom: '20px'
				}}
			>
				<ListItemIcon onClick={() => handleClose()} className="min-w-0" style={{ marginRight: '14px' }}>
					<CircleDeleteIcon />
				</ListItemIcon>
				<ListItemText
					primary={
						<span className="flex items-center">
							<BatteryIcon className="mr-5" />
							<p variant="body2" className="ml-5 font-semibold text-16">
								Batterie
							</p>
						</span>
					}
				/>
			</ListItem>
			<ListItemText
				className={'visible'}
				style={{ width: '100%' }}
				primary={
					<span className="flex items-center justify-end">
						<IncrementDecrement
							handleChange={handleChange}
							value={allAlerts.battery.min}
							valueUnits={'%'}
							title={'min'}
							type={'battery'}
						/>
					</span>
				}
			/>
		</div>
	);
}

export default Battery;
