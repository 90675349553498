import ClientsConfig from './clients/ClientsConfig';
import DevicesConfig from './devices/deviceMainConfig';
import HistoryConfig from './history/HistoryConfig';
import SearchConfig from './search/SearchConfig';
import TransportConfig from './transport/TransportConfig';
import ShareConfig from './share/ShareConfig';
import CotationConfig from './transport/cotation/CotationConfig';
import FashionhubConfig from './fashionhub/FashionhubConfig';
// import NotificationsConfig from './notifications/NotificationsConfig';
// import Search from './search/Search';

const appsConfigs = [
	ClientsConfig,
	DevicesConfig,
	HistoryConfig,
	TransportConfig,
	ShareConfig,
	SearchConfig,
	CotationConfig,
	FashionhubConfig
];

export default appsConfigs;
