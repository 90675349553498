import * as React from 'react';

function ContractorsIcon(props) {
	return (
		<svg
			width={props.size || '24'}
			height={props.size || '24'}
			style={{ marginLeft: props.marginLeft, minWidth: `${props.size || 24}px` }}
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<g clipPath="url(#clip0_15600_14498)">
				<path
					d="M12.5 10.875V10.125C12.5 9.72718 12.342 9.34564 12.0607 9.06434C11.7794 8.78304 11.3978 8.625 11 8.625H8C7.60218 8.625 7.22064 8.78304 6.93934 9.06434C6.65804 9.34564 6.5 9.72718 6.5 10.125V10.875"
					stroke={props.color || 'currentColor'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.5 7.125C10.3284 7.125 11 6.45343 11 5.625C11 4.79657 10.3284 4.125 9.5 4.125C8.67157 4.125 8 4.79657 8 5.625C8 6.45343 8.67157 7.125 9.5 7.125Z"
					stroke={props.color || 'currentColor'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_15600_14498">
					<rect
						width={props.size || '18'}
						height={props.size || '18'}
						fill="white"
						transform="translate(5 3)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default ContractorsIcon;
