import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { TitleTabSettings } from '@stracker/components/title/TitleTabSettings';
import { ButtonStyled } from '@stracker/components/StyledComponents/ButtonStyled';
import ContractorsIcon from '@stracker/customicons/ContractorsIcon';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';

function ContractorsHeader({
	pageRouter,
	setPageRouter,
	displayMenuBurger,
	setBurgerIsOpen,
	burgerIsOpen,
	handleCloseDialogSettings
}) {
	const theme = useTheme();
	const { t } = useTranslation('contractors');

	const user = useSelector(({ auth }) => auth.user);

	return (
		<>
			<div className="w-full flex-col">
				<div className={clsx()} style={{ transition: 'margin-right 0.2s' }}>
					<TitleTabSettings disabledBorder style={{ justifyContent: 'space-between' }}>
						{displayMenuBurger && (
							<MenuIcon className="cursor-pointer mr-10" onClick={() => setBurgerIsOpen(!burgerIsOpen)} />
						)}
						<div className="flex items-center">
							<ContractorsIcon color={theme.palette.stracker.contrastText} />
							<h4 style={{ fontSize: '15px' }}>{t('CONTRACTORS')}</h4>
						</div>

						<div className="flex mr-6 md:mr-0">
							{pageRouter !== '' ? null : (
								<ButtonStyled
									type="button"
									className={clsx(
										user.occupation !== 'userAdmin' || user.occupation !== 'superAdmin'
											? null
											: 'hidden'
									)}
									style={{ transition: 'all 0.2s', padding: '7px 10px' }}
									onClick={() => setPageRouter('addContractor')}
								>
									{t('ADD_CONTRACTORS')}
								</ButtonStyled>
							)}
						</div>
						{displayMenuBurger && (
							<CancelIcon className=" cursor-pointer ml-auto" onClick={handleCloseDialogSettings} />
						)}
					</TitleTabSettings>
				</div>
			</div>
		</>
	);
}

export default ContractorsHeader;
