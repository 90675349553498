import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FuseAnimateGroup from '../../../../@fuse/core/FuseAnimateGroup/FuseAnimateGroup';
import NotificationsItem from './NotificationsItem';
import { ContainerScrollDivPage } from '../../../../@stracker/components/StyledComponents/ContainerScrollDivPage';

const NotificationsContent = ({
	notificationsData,
	sortBy,
	selectValueState,
	displayFilterSelect,
	searchTextValue
}) => {
	const [data, setData] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [plage, setPlage] = useState(12);

	const searchedText = async () => {
		const dataCopy = [...notificationsData];
		return dataCopy.filter(
			notification =>
				notification.content.toLowerCase().includes(searchTextValue.toLowerCase()) ||
				notification.idNotification.toString().includes(searchTextValue) ||
				notification.title.toLowerCase().includes(searchTextValue.toLowerCase())
		);
	};

	const sortData = async filterBySearch => {
		const dataSorted = [...filterBySearch];

		dataSorted.sort(function compare(a, b) {
			const dateA = new Date(a.createdAt);
			const dateB = new Date(b.createdAt);
			if (sortBy === 'ASC') {
				return dateB - dateA;
			}
			return dateA - dateB;
		});

		return dataSorted;
	};

	const filterDataBySelectState = dataSorted => {
		const dataCopy = [...dataSorted];
		let dataFiltered;

		if (selectValueState === 'read') {
			dataFiltered = dataCopy.filter(notification => notification.isRead === 1);
		} else if (selectValueState === 'unread') {
			dataFiltered = dataCopy.filter(notification => notification.isRead === 0);
		} else {
			dataFiltered = dataCopy;
		}
		setData([...dataFiltered]);
	};

	const fetchMoreData = () => {
		if (hasMore) {
			if (data.length < plage) {
				setHasMore(false);
			} else {
				setPlage(plage + 3);
			}
		}
	};

	useEffect(() => {
		searchedText().then(filterBySearch => {
			sortData(filterBySearch).then(dataSorted => {
				filterDataBySelectState(dataSorted);
			});
		});
		// eslint-disable-next-line
	}, [sortBy, selectValueState, displayFilterSelect, searchTextValue, notificationsData]);

	return (
		<ContainerScrollDivPage id="scrollableDivNotification">
			<FuseAnimateGroup enter={{ animation: 'transition.slideUpBigIn' }}>
				<InfiniteScroll
					dataLength={data.slice(0, plage).length}
					next={fetchMoreData}
					hasMore={hasMore}
					scrollThreshold="90%"
					hasChildren={hasMore}
					loader={<p> chargement des autres notifications</p>}
					endMessage={
						<div className="flex justify-center items-center p-40">
							<p>
								{selectValueState === 'read'
									? 'fin des notifications totals lus'
									: selectValueState === 'unread'
									? 'fin des notifications totals non lus'
									: 'fin des notifications totals'}
							</p>
						</div>
					}
					scrollableTarget="scrollableDivNotification"
					easing="0.4,0,0.2,1"
				>
					{data.length > 0 ? (
						data
							.slice(0, plage)
							.map(notification => (
								<NotificationsItem key={notification.idNotification} notification={notification} />
							))
					) : (
						<div className="flex h-full justify-center items-center text-center pt-32 overflow-hidden">
							<p className="text-2xl">Aucune Notification...</p>
						</div>
					)}
				</InfiniteScroll>
			</FuseAnimateGroup>
		</ContainerScrollDivPage>
	);
};

export default NotificationsContent;
