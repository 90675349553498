const locale = {
	ADD_CONTRACTORS: "Ajouter un donneur d'ordre",
	CREATE: 'Valider',
	CANCEL: 'Annuler',
	UPDATE: 'Valider',
	PLACEHOLDER_SEARCH: 'Rechercher',
	CONTRACTORS: "Donneur d'ordre",
	MODIFYCONTRACTOR: "Modifier un donneur d'ordre",
	IDENTITY: 'Identité',
	EMAIL: 'Adresse mail',
	PHONE: 'Téléphone',
	ROLE: 'Fonction',
	CONTRACTORMODIF: 'Modifier',
	CONTRACTORSUPPR: 'Supprimer',
	CONFIRM_DELETE_CONTRACTOR: "Voulez-vous vraiment supprimer le donneur d'ordre ?",
	DELETE: 'Supprimer'
};

export default locale;
