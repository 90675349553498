import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function BatteryIcon(props) {
	const theme = useTheme();
	return (
		<svg
			width={props.width || '18'}
			height={props.height || '18'}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_9915_3506)">
				<path
					d="M12.75 4.5H2.25C1.42157 4.5 0.75 5.17157 0.75 6V12C0.75 12.8284 1.42157 13.5 2.25 13.5H12.75C13.5784 13.5 14.25 12.8284 14.25 12V6C14.25 5.17157 13.5784 4.5 12.75 4.5Z"
					stroke={theme.palette.stracker.contrastTextDark}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M17.25 9.75V8.25"
					stroke={theme.palette.stracker.contrastTextDark}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9915_3506">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default BatteryIcon;
