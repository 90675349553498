const locale = {
	ADDCONTENU: 'Add contents',
	ADDMASTER: 'Add a container',
	ADD_BUTTON: 'Add',
	ALERTGEN: 'Alerts - custom',
	ASSETS: 'Asset(s)',
	ASSOCIE: 'Link',
	ASSOCIERCONTENANT1: 'Link to a container',
	ASSOCIERCONTENANT2: 'Type of container',
	ASSOCIERCONTENANT3: 'Link you container to a tracker',
	ASSOCIERCONTENANT4: 'Customized the alerts for your container',
	ASSOCIERCONTENANT: 'Pairing',
	CANCEL: 'Cancel',
	CAPTEUR: 'Sensors',
	COMMENT_BACK: 'Close',
	COMMENT_BUTTON: 'Add a comment',
	COMMENT_CONFIRM_DELETE: 'Some comments will be deleted. Are you sure?',
	COMMENT_TITLE: 'Comments -',
	CONTAINER_INFO: 'Information about the asset',
	CONNEXION: 'Connectivity',
	CONTENU: 'Content(s)',
	CONTENU_ADD_TITLE: 'Add contents',
	CONTENU_BACK: 'Close',
	CONTENU_CONFIRM_DELETE: 'Some content will be deleted. Are you sure?',
	CREATEAT: 'Created',
	DANGER: 'Warning !',
	DATE_PICKER_BEGIN: 'Begin',
	DATE_PICKER_END: 'End',
	DELETEMASTER: 'Delete',
	DELETEMASTERDIALOG: 'Are you sure to delete the container ',
	DELETEMASTERTITLE: 'Delete a container',
	DEPRISEENCHARGE: 'End control',
	DESASSOCIE: 'Dissociate',
	DIALOGEXPLICATION1: 'Define under which reference you want to identify this container ',
	DIALOGEXPLICATION1BIS: 'Container reference',
	DIALOGEXPLICATION2: 'Select the type of facility to localize your assets',
	DIALOGEXPLICATION3:
		'Select through the list of the search bar the serial number of the tracker to be linked to your container',
	DIALOGEXPLICATION4: 'Select the data that you want to monitor as well as the alerts for which you will be notified',
	DISSOCIATE: 'Dissociate',
	EDITMASTER: 'Edit',
	EDITMASTERTITLE: 'Edit a container',
	END: 'Close',
	ENDSCROLL: 'Last reached',
	ENDTRACKEDDIALOG: 'Save',
	FIXE: 'Stationary',
	GESTION_NOTIFICATION: 'Notification management',
	HUMIDITY_HISTORY: 'Humidity history',
	IAM_IN_CHARGE: 'I am in charge',
	IS_IN_CHARGE: 'is in charge',
	LISTTRACKER: 'tracker(s) available',
	LOADING: 'Loading',
	MASTER: 'Container',
	MOBILE: 'Moving',
	NEW: 'Create a container',
	NOTIFICATION_CONFIRM_DELETE: 'will be deleted. Are you sure?',
	NOTIFICATION_CONFIRM_UPDATE: 'Some content will be updated. Are you sure?',
	NOTIFICATION_EMAIL: 'Notification by email',
	NOTIFICATION_SMS: 'Notification by SMS',
	NOTIFICATION_SUBTITLE: 'Set-up the recipients of the notifications related to this asset',
	POSITION: 'Position',
	PRESETALERT: 'Alerts - favorites',
	PRISEENCHARGE: 'Under my control',
	SAVE: 'Save',
	SAVEMASTER: 'Save',
	SEARCHHEADER: 'Search',
	SEEMORE_TITLE: 'Information',
	TEMPERATURE_HISTORY: 'Temperature history',
	TITLE: 'Page Example ',
	TITLE_INFO: 'Notes',
	TRACKEDMASTER: 'Connected container(s)',
	TYPOLOGIE: 'Typology',
	TYPOLOGIEDETAIL: 'Choose the class of your container',
	UNLINKMASTERDIALOG: 'Are you sure to unlink the container ',
	UNLINKMASTERDIALOGBIS: ' from the tracker',
	UNLINKMASTERTITLE: 'End of tracking',
	UNTRACKEDMASTER: 'Container(s)',
	UPDATE: 'Edit a container',
	UP_TO_DATE: 'Asset information',
	NOTIFICATIONS: 'Notifications',
	REFRESH: 'Refresh',
	DOWNLOAD_CSV: 'Download in CSV',
	SHARE: 'share',
	NEXT: 'Next',
	PREVIOUS: 'Previous',
	CUSTOM_EVENT: 'Comment',
	CUSTOM_EVENT_SUBTITLE: 'Describe the element to be added to the list of events',
	DESCRIBE_EVENT: 'Details',
	SEND_NOTIFICATION: 'Send a notification',
	DONT_SEND_NOTIFICATION: 'Do not send a notification',
	ADD: 'Add',
	SHARED_FILES: 'Shared files',
	LIST: 'List',
	TEXT_SHARED_FILES:
		'Here is the list of all the documents related to your transport. To download a file, click on it',
	TEXT_ADD_FILES:
		'Select a category related to the file to be uploaded. For a smooth uploading, the maximum size of a file is limited to 2Mo',
	ADD_FILE: 'Add a file',
	CLOSE: 'Close',
	NEW_FILE: 'New file',
	UPLOAD_FROM_COMPUTER: 'Upload from your computer',
	SELECT_CATEGORY: 'Select Category',
	QUOTE: 'Quote',
	PACKING_LIST: 'Packing list',
	INVOICE: 'Invoice',
	DGR: 'DGR',
	TRANSPORT: 'Transport',
	CUSTOM: 'Custom',
	PICK_UP: 'Pick-Up',
	UPDATE_CATEGORY: 'Update',
	DELIVERY: 'Delivery',
	REPORT: 'Report',
	BILING: 'Biling',
	OTHER: 'Other',
	AGENT: 'Agent',
	TEXT_UPLOAD_FILE: 'This file has been uploaded : ',
	UPLOAD: 'Uploading',
	OVERSIZED_FILE: 'Maximum size of file is 5Mo',
	EXISTING_FILE: 'This file already exist',
	NO_CATEGORY: 'Category has not been defined',
	NO_FILE: 'No file has been upload',
	DELETE: 'Delete',
	DELETE_FILE: 'Delete this file',
	DELETE_FILE_TEXT: 'Are you sure you want to delete this file ?',
	ADD_EVENT: 'Add an event'
};

export default locale;
