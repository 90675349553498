import React from 'react';
import clsx from 'clsx';
import MinusIcon from '@stracker/customicons/MinusIcon';
import AddIcon from '@stracker/customicons/AddIcon';
import { makeStyles, IconButton, useTheme } from '@material-ui/core';

function IncrementDecrement({ handleChange, value, valueUnits, title, type }) {
	const useStyles = makeStyles(theme => ({
		font1: { color: theme.palette.stracker.contrastTextDark, fontWeight: '600' },
		miniSetters: {
			border: `solid 2px ${theme.palette.stracker.contrastTextMain}`,
			borderRadius: '8px',
			width: '110px',
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'space-evenly',
			flexDirection: 'row',
			alignItems: 'center',
			paddingTop: '3px',
			paddingBottom: '3px'
		},
		inputSetter: {
			color: theme.palette.stracker.contrastTextDark,
			fontSize: '15px',
			fontWeight: '600',
			textAlign: 'center',
			width: '25px',
			background: theme.palette.stracker.card
		},
		number: {
			'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0
			},
			'& input[type=number]': {
				'-moz-appearance': 'textfield'
			}
		}
	}));

	const classes = useStyles();
	const theme = useTheme();

	return (
		<div style={{ marginLeft: title === 'max' ? '10px' : '0px' }}>
			<p
				className={clsx(classes.font1)}
				style={{ color: theme.palette.stracker.contrastTextLight, fontSize: '12px' }}
				variant="body1"
			>
				{title}
			</p>
			<div className={clsx(classes.miniSetters)}>
				<IconButton onClick={() => handleChange('decrement', type, title)} size="small">
					<MinusIcon />
				</IconButton>
				<input
					id="geofence"
					className={clsx('inline-block w-28', classes.inputSetter, classes.number)}
					value={value}
					type="text"
					maxLength="2"
					onChange={ev => handleChange(ev.target.value, type, title)}
				/>
				<p className={clsx(classes.font1)}> {valueUnits}</p>
				<IconButton onClick={() => handleChange('increment', type, title)} size="small">
					<AddIcon />
				</IconButton>
			</div>
		</div>
	);
}

export default IncrementDecrement;

/*

            PROTOTYPE HANDLECHANGE

	const handleChange = ev => {
		if (ev === 'decrement' && data.geofence > 1) data.geofence -= 1;
		else if (ev === 'increment') data.geofence += 1;
		else if (ev !== 'decrement' && ev !== 'increment') data.geofence = ev;
		dispatch(Actions.updateContent4());
	};

*/
