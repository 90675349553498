import React, { useState } from 'react';
import Formsy from 'formsy-react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions } from '@material-ui/core';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import * as Actions from './store/actions';
import MappinIcon from '@stracker/customicons/MappinIcon';
import HumidityIcon from '@stracker/customicons/HumidityIcon';
import BatteryIcon from '@stracker/customicons/BatteryIcon';
import ThermometerIcon from '@stracker/customicons/ThermometerIcon';
import NotificationIcon from '@stracker/customicons/NotificationIcon';
import { ButtonStyled } from '@stracker/components/StyledComponents/ButtonStyled';
import { TextFieldFormsyStyled } from '@stracker/components/StyledComponents/TextFieldFormsyStyled';
import Temperature from './tabs/Temperature';
import Humidity from './tabs/Humidity';
import Battery from './tabs/Battery';
import Geofencing from './tabs/Geofencing';
import Route from './tabs/Route';

function AlertsAddAlert({ heightHeader, setDisplayAddAlert, alertsData, setAlertsData }) {
	const theme = useTheme();
	const { t } = useTranslation('alerts');
	const dispatch = useDispatch();
	const [alertName, setAlertName] = useState(alertsData ? alertsData.name : '');

	const user = useSelector(({ auth }) => auth.user);

	const alertsDataGeofence = [];
	if (alertsData && alertsData.geofence.length)
		alertsData.geofence.forEach(data => {
			alertsDataGeofence.push({
				fullAddress: data.address,
				latitude: data.gpsLocation ? parseFloat(data.gpsLocation.split(/[ ,]+/)[0]) : 0,
				longitude: data.gpsLocation ? parseFloat(data.gpsLocation.split(/[ ,]+/)[1]) : 0,
				country: null,
				city: null,
				street: null,
				tripNumber: null,
				direction: data.direction,
				gpsLocation: null,
				min: data.ruleValueMin,
				name: 'geofencing',
				IN: data.direction === 'INOUT' || data.direction === 'IN' ? true : false,
				OUT: data.direction === 'INOUT' || data.direction === 'OUT' ? true : false,
				show: null
			});
		});

	const [allAlerts, setAllAlerts] = useState({
		id: '',
		name: '',
		idCustomer: user.data.idCustomer,
		idUser: user.data.idUser,
		temperature:
			alertsData && typeof alertsData.temperature === 'object' && !Array.isArray(alertsData.temperature)
				? {
						display: true,
						min: alertsData.temperature.ruleValueMin,
						max: alertsData.temperature.ruleValueMax,
						name: 'temperature'
				  }
				: {
						display: false,
						min: 2,
						max: 8,
						name: 'temperature'
				  },
		humidity:
			alertsData && typeof alertsData.humidity === 'object' && !Array.isArray(alertsData.humidity)
				? {
						display: true,
						min: alertsData.humidity.ruleValueMin,
						max: alertsData.humidity.ruleValueMax,
						name: 'humidity'
				  }
				: {
						display: false,
						min: 20,
						max: 60,
						name: 'humidity'
				  },
		battery:
			alertsData && typeof alertsData.battery === 'object' && !Array.isArray(alertsData.battery)
				? {
						display: true,
						min: alertsData.battery.ruleValueMin,
						name: 'battery'
				  }
				: {
						display: false,
						min: 33,
						name: 'battery'
				  },
		geofence: alertsData && alertsData.geofence.length ? alertsDataGeofence : []
	});

	const selectValues = [
		{
			selectName: 'addAlert',
			values: [],
			placeholder: 'Ajouter Alerte',
			placeholderIcon: <NotificationIcon />,
			options: [
				{
					value: 'temperature',
					checked: false,
					icon: (
						<div className="flex items-center mr-5">
							<ThermometerIcon color={theme.palette.stracker.contrastTextDark} />
						</div>
					),
					label: (
						<div className="flex items-center">
							<div className="flex">
								<ThermometerIcon color={theme.palette.stracker.contrastTextDark} />
							</div>
							<span className="ml-7 text-14">Température</span>
						</div>
					)
				},
				{
					value: 'humidity',
					checked: false,
					icon: (
						<div className="flex items-center mr-3">
							<HumidityIcon width={20} height={20} color={theme.palette.stracker.contrastTextDark} />
						</div>
					),
					label: (
						<div className="flex items-center">
							<div className="flex">
								<HumidityIcon width={20} height={20} color={theme.palette.stracker.contrastTextDark} />
							</div>
							<span className="ml-5 text-14">Humidité</span>
						</div>
					)
				},
				{
					value: 'battery',
					checked: false,
					icon: (
						<div className="flex items-center mr-5">
							<BatteryIcon />
						</div>
					),
					label: (
						<div className="flex items-center">
							<div className="flex">
								<BatteryIcon />
							</div>
							<span className="ml-7 text-14">Batterie</span>
						</div>
					)
				},
				{
					value: 'geofence',
					checked: false,
					icon: (
						<div className="flex items-center mr-5">
							<MappinIcon color={theme.palette.stracker.contrastTextDark} />
						</div>
					),
					label: (
						<div className="flex items-center">
							<div className="flex">
								<MappinIcon color={theme.palette.stracker.contrastTextDark} />
							</div>
							<span className="ml-7 text-14">Géofence</span>
						</div>
					)
				}
			]
		}
	];

	const handleChangeValueSelect = (selectedOption, index) => {
		const allAlertCpy = { ...allAlerts };
		if (selectedOption.value === 'temperature') {
			allAlertCpy.temperature.display = true;
		}
		if (selectedOption.value === 'humidity') {
			allAlertCpy.humidity.display = true;
		}
		if (selectedOption.value === 'battery') {
			allAlertCpy.battery.display = true;
		}
		if (selectedOption.value === 'geofence' && user.data.typeClient !== 'TR') {
			allAlertCpy.geofence.push({
				fullAddress: null,
				latitude: null,
				longitude: null,
				country: null,
				city: null,
				street: null,
				tripNumber: null,
				direction: 'INOUT',
				gpsLocation: null,
				min: 5,
				name: 'geofencing',
				IN: true,
				OUT: true,
				show: null
			});
		}
		if (selectedOption.value === 'geofence' && user.data.typeClient === 'TR') {
			allAlertCpy.geofence.push({
				fullAddress: null,
				latitude: null,
				longitude: null,
				country: null,
				city: null,
				street: null,
				tripNumber: null,
				direction: 'OUT',
				gpsLocation: null,
				min: 5,
				name: 'geofencing',
				IN: true,
				OUT: true,
				show: null
			});
			allAlertCpy.geofence.push({
				fullAddress: null,
				latitude: null,
				longitude: null,
				country: null,
				city: null,
				street: null,
				tripNumber: null,
				direction: 'IN',
				gpsLocation: null,
				min: 5,
				name: 'geofencing',
				IN: true,
				OUT: true,
				show: null
			});
		}
		setAllAlerts(allAlertCpy);
	};

	const handleInputChange = e => {
		const { value } = e.target;
		setAlertName(value);
	};

	const saveModification = () => {
		allAlerts.name = alertName;
		allAlerts.geofence.forEach(data => {
			data.gpsLocation = `${data.latitude.toString()} ${data.longitude.toString()}`;
		});
		dispatch(Actions.updateAlert(allAlerts));
		setDisplayAddAlert(false);
	};

	return (
		<ContainerContentToolbarStyled
			className="overflow-scroll py-10 px-5 rounded-lg"
			style={{
				height: `calc(100% - ${heightHeader + 55}px)`
			}}
		>
			<div>
				<Formsy className="flex overflow-hidden flex-col justify-center">
					<TextFieldFormsyStyled
						name="alertname"
						type="text"
						placeholder={t('ALERT_NAME')}
						value={alertName}
						onChange={e => handleInputChange(e)}
						variant="outlined"
					/>
				</Formsy>
				{user.data.typeClient === 'TR' ? (
					<Route allAlerts={allAlerts} setAllAlerts={setAllAlerts} />
				) : (
					<Geofencing allAlerts={allAlerts} setAllAlerts={setAllAlerts} />
				)}
				{allAlerts.temperature.display ? (
					<Temperature allAlerts={allAlerts} setAllAlerts={setAllAlerts} />
				) : null}
				{allAlerts.humidity.display ? <Humidity allAlerts={allAlerts} setAllAlerts={setAllAlerts} /> : null}
				{allAlerts.battery.display ? <Battery allAlerts={allAlerts} setAllAlerts={setAllAlerts} /> : null}
			</div>
			{selectValues.map((select, index) => {
				const Placeholder = ({ children, ...props }) => {
					return (
						<components.Placeholder {...props}>
							<div className="flex items-center">
								{props.selectProps.placeholderIcon}
								<span className="ml-10">{children}</span>
							</div>
						</components.Placeholder>
					);
				};
				return (
					<div
						style={{ position: 'fixed', bottom: '0px', left: '15px', width: '200px' }}
						className="px-10 py-7"
					>
						<Select
							key={'select'}
							placeholder={select.placeholder}
							placeholderIcon={select.placeholderIcon}
							styles={{
								placeholder: base => ({
									...base,
									fontSize: '14px',
									color: theme.palette.stracker.contrastTextDark,
									fontWeight: 600,
									backgroundColor: theme.palette.stracker.card
								}),
								control: base => ({
									...base,
									borderRadius: '8px',
									border: `1px solid ${theme.palette.stracker.contrastTextMain}`,
									backgroundColor: theme.palette.stracker.card
								}),
								indicatorsContainer: base => ({
									...base,
									color: 'red',
									backgroundColor: theme.palette.stracker.card
								}),
								input: base => ({
									...base,
									'& input': {
										fontWeight: '600',
										fontFamily: 'Open Sans',
										fontSize: '14px',
										color: theme.palette.stracker.contrastTextDark,
										backgroundColor: theme.palette.stracker.card
									}
								}),
								menu: base => ({
									...base,
									backgroundColor: theme.palette.stracker.card
								}),
								valueContainer: base => ({
									...base,
									backgroundColor: theme.palette.stracker.card
								})
							}}
							components={{ Placeholder }}
							menuPlacement="top"
							onChange={e => handleChangeValueSelect(e, index)}
							options={select.options}
							value="no display value"
						/>
					</div>
				);
			})}
			<DialogActions style={{ position: 'fixed', bottom: '0px', right: '15px' }}>
				<ButtonStyled
					type="button"
					className="py-7 px-10 ml-5"
					onClick={() => setDisplayAddAlert(false)}
					secondary
				>
					{t('BOUTON_CANCEL')}
				</ButtonStyled>
				<ButtonStyled type="button" className="py-7 px-10 ml-5" onClick={() => saveModification()}>
					{t('BOUTON_VALIDER')}
				</ButtonStyled>
			</DialogActions>
		</ContainerContentToolbarStyled>
	);
}

const ContainerContentToolbarStyled = styled.div`
	[class$='indicatorContainer'] {
		color: ${props => props.theme.palette.stracker.contrastTextDark};
	}, 
`;

export default AlertsAddAlert;
