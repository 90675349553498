const locale = {
	ADD: 'Ajouter',
	ADDASSET: 'Ajouter un actif',
	ADDCONTENU: 'Ajouter un contenu',
	ALERT: 'Alerte',
	ALERTGEN: 'Alertes - personnalisées',
	ASSET: 'Actif',
	ASSOCIE: 'Associer',
	ASSOCIERCONTENANT1: 'Créer votre actif',
	ASSOCIERCONTENANT2: 'Associer votre actif à un tracker',
	ASSOCIERCONTENANT3: 'Ajouter du contenu à votre actif',
	ASSOCIERCONTENANT4: 'Spécifier les alertes de votre actif',
	CANCEL: 'Annuler',
	CAPTEUR: 'Capteurs',
	CLOSE_TRANSPORT: "Archivage d'un transport",
	SUPPR_TRANSPORT: "Suppression d'un transport",
	COMMENT: 'Commentaires',
	COMMENT_BACK: 'Fermer',
	COMMENT_BUTTON: 'Ajouter un commentaire',
	COMMENT_CONFIRM_DELETE: 'Le commentaire sera effacé. Etes-vous sûr ?',
	COMMENT_TITLE: 'Commentaires - ',
	CONTAINER_INFO: 'Informations sur le colis',
	CONNEXION: 'Connectivité',
	CONTENU: 'Contenu(s)',
	CONTENU_ADD_TITLE: 'Ajouter du contenu',
	CONTENU_BACK: 'Fermer',
	CONTENU_CONFIRM_DELETE: 'Du contenu sera effacé. Etes-vous sûr ?',
	CONTENU_UPBDATE_B: 'Terminé',
	CREATEAT: 'Créé',
	CURRENT_ROUTE: 'Parcours',
	DANGER: 'Attention !',
	DATE_PICKER_BEGIN: 'Début',
	DATE_PICKER_END: 'Fin',
	DELETEASSET: 'Supprimer',
	DELETEASSETDIALOG: "Etes-vous sûr de vouloir supprimer l'actif ",
	DELETEASSETTITLE: 'Suppression',
	DELETE_TRANSPORT: 'Etes-vous sûr de vouloir archiver ce transport ?',
	SUPPR_TRANSPORT_TXT: 'Etes-vous sûr de vouloir supprimer définitivement ce transport ?',
	ARCHIVE: 'Archiver',
	DELETE_ASSET: 'Supprimer colis',
	DELETE_ASSET_TEXT:
		"Etes-vous sûr de vouloir supprimer ce colis ? Si oui, vous pouvez opter pour un recyclage des trackers s'il s'agit d'un usage unique ou d'une remise en stock si vous souhaitez les utiliser ultérieurement pour de nouveaux transports",
	DEPRISEENCHARGE: 'Fin de prise en charge',
	DESASSOCIE: 'Dissocier',
	DIALOGEXPLICATION1: "Définissez la référence vous permettant d'identifier cet actif",
	DIALOGEXPLICATION1BIS: 'Référence de votre actif',
	DIALOGEXPLICATION2:
		'Sélectionnez dans la liste ou dans la recherche le numéro du tracker qui sera associé à votre actif',
	DIALOGEXPLICATION3: 'Vous pouvez décrire le contenu de votre actif (optionnel)',
	DIALOGEXPLICATION4:
		'Sélectionnez les données de votre actif que vous souhaitez visualiser, ainsi que les alertes pour lesquelles vous serez notifié',
	DISSOCIATE: 'Dissocier',
	DOWNLOAD_CSV: 'Télécharger en CSV',
	EDIT: 'Modifier',
	EDITASSET: 'Modifier',
	EDITASSETTITLE: 'Modifier un actif',
	ENDSCROLL: 'Dernier atteint',
	ENDTRACKEDDIALOG: 'Finaliser',
	GEOFENCE: 'Géofence',
	HIDE_FILTERS: 'Masquer les filtres',
	HUMIDITY: 'Humidité',
	HUMIDITY_HISTORY: "Historique d'humidité",
	IAM_IN_CHARGE: "je m'en charge",
	IS_IN_CHARGE: "s'en charge",
	LISTTRACKER: 'Tracker(s) disponibles',
	LOADING: 'Chargement',
	MODIFY_TRANSPORT_ALERT: 'Modifier les alertes du transport',
	NEW: "Création d'un actif",
	NOTIFICATIONS: 'Notifications',
	NOTIFICATION_CONFIRM_DELETE: 'sera supprimé. Etes-vous sûr ?',
	NOTIFICATION_CONFIRM_UPDATE: 'Du contenu sera modifié. Etes-vous sûr ?',
	NOTIFICATION_EMAIL: 'Notification par email',
	NOTIFICATION_SMS: 'Notification par SMS',
	NOTIFICATION_SUBTITLE: "Paramétrer l'envoi des notifications relatives à cet actif",
	POSITION: 'Position',
	PRESETALERT: 'Alertes - favoris',
	PRISEENCHARGE: "Je m'en charge",
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	RECYCLE: 'Recycler',
	REFRESH: 'Rafraichir',
	SAVE: 'Valider',
	SAVEASSET: 'Valider',
	SEARCHHEADER: 'Rechercher',
	SEEMORE_TITLE: 'Informations',
	SHARE: 'Partager',
	SHOW_FILTERS: 'Filtrer',
	STOCK: 'Stocker',
	TEMPERATURE: 'Température',
	TEMPERATURE_HISTORY: 'Historique des températures',
	TITLE: 'Exemple de page ',
	TITLE_INFO: 'Informations libres',
	TRACKEDASSET: 'Actif(s) connecté(s)',
	TRACKERS: 'Trackers',
	UNLINKASSETDIALOG: "Etes-vous sûr de vouloir dissocier l'actif",
	UNLINKASSETDIALOGBIS: ' du tracker',
	UNLINKASSETTITLE: "Dissociation d'un actif",
	UNTRACKEDASSET: 'Actif(s)',
	UPDATE2: 'Modifier',
	UPDATE: "Modification des alertes de l'actif",
	UP_TO_DATE: 'Informations à jour',
	NEXT: 'Suivant',
	PREVIOUS: 'Précédent',
	NO_MORE_CHARGE: 'Ne plus le prendre en charge',
	CHARGE_BY: 'Prise en charge par',
	CHARGE_IT: 'Le prendre en charge',
	TEXT_UPLOAD_FILE: 'Fichier envoyé : ',
	UPLOAD_FILE: 'Télécharger document',
	CONVERSATION_OF: 'Conversation de',
	CONVERSATION: 'Conversation',
	SEARCH: 'Rechercher',
	MANUAL_POSITION: 'Position Manuelle',
	MANUAL_POSITION_TEXT: 'Ajouter une position sur la carte',
	ADD_POSITION: 'Ajout Position',
	DEPARTURE: 'Départ',
	STEP: 'Etape',
	ARRIVAL: 'Arrivée',
	MANUAL: 'Manuelle',
	DEPARTURE_POSITION: 'Position Départ',
	STEP_POSITION: 'Position Etape',
	ARRIVAL_POSITION: 'Position Arrivée',
	ERROR_DATE: 'Date d’arrivée antérieure à la date de départ',
	ERROR_TRIP: 'Il manque des renseignements dans le parcours'
};

export default locale;
