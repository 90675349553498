import React, { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import CircleAddIconBlue from '@stracker/customicons/CircleAddIconBlue';
import CircleMinusIcon from '@stracker/customicons/CircleMinusIcon';
import MappinIcon from '@stracker/customicons/MappinIcon';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import IncrementDecrement from '@stracker/components/IncrementDecrement';
import DialogPingMap from '@stracker/components/Dialogs/DialogPingMap';
import CrossHair from '@stracker/customicons/CrossHair';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GeocodingStrackerGoogleMap from '../../../../apps/transport/customComponents/GeocodingStrackerGoogleMap.js';

function GeoFencing({ allAlerts, setAllAlerts }) {
	const useStyles = makeStyles(theme => ({
		font1: { color: theme.palette.stracker.contrastTextDark, fontWeight: '600' }
	}));

	const theme = useTheme();
	const classes = useStyles();
	const { t } = useTranslation('alerts');
	const isMobileDevice = useMediaQuery('(max-width:960px)');

	const [globalCode, setGlobalCode] = useState({
		transit: []
	});
	const [currentDialogPing, setCurrentDialogPing] = useState(null);
	const [openDialogPingMap, setOpenDialogPingMap] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const deleteStep = index => {
		allAlerts.geofence.splice(index, 1);
		const copyGlobalCode = globalCode;
		copyGlobalCode.transit.splice(index, 1);
		setGlobalCode(copyGlobalCode);
		setRefresh(!refresh);
	};

	const handleChange = (ev, index) => {
		if (ev !== 'decrement' && ev !== 'increment') allAlerts.geofence[index].min = parseInt(ev);
		else if (ev === 'decrement' && allAlerts.geofence[index].min > 1) allAlerts.geofence[index].min -= 1;
		else if (ev === 'increment') allAlerts.geofence[index].min += 1;
		setRefresh(!refresh);
	};

	const modifyDirectionValue = (index, key) => {
		if (key === 'IN') allAlerts.geofence[index].IN = !allAlerts.geofence[index].IN;
		else if (key === 'OUT') allAlerts.geofence[index].OUT = !allAlerts.geofence[index].OUT;
		if (allAlerts.geofence[index].IN === true && allAlerts.geofence[index].OUT)
			allAlerts.geofence[index].direction = 'INOUT';
		else if (allAlerts.geofence[index].IN === true) allAlerts.geofence[index].direction = 'IN';
		else if (allAlerts.geofence[index].OUT === true) allAlerts.geofence[index].direction = 'OUT';
		setRefresh(!refresh);
	};

	const handleOpenDialogMapPing = e => {
		e.stopPropagation();
		setOpenDialogPingMap(true);
		setCurrentDialogPing(e.target.name);
	};

	const handleCloseDialogMapPing = () => {
		setOpenDialogPingMap(false);
	};

	const generateCoordinate = typeCoordinate => {
		if (!currentDialogPing) {
			if (typeCoordinate === 'lat') return null;
			if (typeCoordinate === 'lng') return null;
		}
		if (!currentDialogPing.includes('step')) {
			if (typeCoordinate === 'lat') {
				return globalCode[currentDialogPing].coordinate.lat;
			}
			return globalCode[currentDialogPing].coordinate.lng;
		}
		if (currentDialogPing.includes('step')) {
			const stepNumber = currentDialogPing.replace('step', '');
			const parseStepNumber = parseInt(stepNumber, 10);
			if (!globalCode.transit[parseStepNumber - 1]) return null;

			if (typeCoordinate === 'lat') {
				return globalCode.transit[parseStepNumber - 1].coordinate.lat;
			}
			return globalCode.transit[parseStepNumber - 1].coordinate.lng;
		}
	};

	const validAdresseTransit = (address, index) => {
		allAlerts.geofence[index].fullAddress = address.fullAddress;
		allAlerts.geofence[index].latitude = address.latitude;
		allAlerts.geofence[index].longitude = address.longitude;
		allAlerts.geofence[index].country = address.address.country ? address.address.country : '';
		allAlerts.geofence[index].city = address.address.city ? address.address.city : '';
		allAlerts.geofence[index].street = address.address.street ? address.address.street : '';
		allAlerts.geofence[index].tripNumber = index + 1;
		allAlerts.geofence[index].show = true;
	};

	return allAlerts.geofence.map((data, index) => (
		<div
			key={index}
			name="transit"
			style={{
				paddingLeft: '7px',
				paddingTop: '20px',
				paddingBottom: '20px',
				borderBottom: 'solid 1px',
				borderColor: theme.palette.stracker.colorOutlined
			}}
		>
			<div className="flex flex-row items-center">
				<div className="flex flex-row items-center">
					<Tooltip title={t('DELETE_A_STEP')}>
						<IconButton
							id={index + 1}
							size="small"
							onClick={ev => deleteStep(index)}
							style={{ marginRight: '7px' }}
						>
							<CircleDeleteIcon />
						</IconButton>
					</Tooltip>
					<MappinIcon />
					<p className={clsx(classes.font1, 'ml-6')}>
						{t('ETAPE')} {index + 1}
					</p>
				</div>
			</div>

			<div className="flex justify-between">
				<div>
					<p
						style={{ marginLeft: '4px', fontSize: '12px', color: theme.palette.stracker.contrastTextLight }}
						variant="body1"
					>
						{t('ALERTING')}
					</p>
					<div className="flex" style={{ marginTop: '3px' }}>
						<div className="flex items-center">
							<IconButton size="small" onClick={() => modifyDirectionValue(index, 'IN')}>
								{allAlerts.geofence[index].IN === true ? <CircleMinusIcon /> : <CircleAddIconBlue />}
							</IconButton>
							<p className={clsx(classes.font1, 'ml-4')}>{t('IN')}</p>
						</div>
						<div className="flex items-center ml-14">
							<IconButton size="small" onClick={() => modifyDirectionValue(index, 'OUT')}>
								{allAlerts.geofence[index].OUT === true ? <CircleMinusIcon /> : <CircleAddIconBlue />}
							</IconButton>
							<p className={clsx(classes.font1, 'ml-4')}>{t('OUT')}</p>
						</div>
					</div>
				</div>
				<div>
					<IncrementDecrement
						handleChange={handleChange}
						value={allAlerts.geofence[index].min}
						valueUnits={'km'}
						title={t('GEOFENCE')}
						type={index}
					/>
				</div>
			</div>

			<DialogPingMap
				openDialogPingMap={openDialogPingMap}
				handleCloseDialogMapPing={handleCloseDialogMapPing}
				setGlobalCode={setGlobalCode}
				globalCode={globalCode}
				currentDialogPing={currentDialogPing}
				latState={generateCoordinate('lat')}
				lngState={generateCoordinate('lng')}
				getUpdateAddressTransit={validAdresseTransit}
				isMobileDevice={isMobileDevice}
				t={t}
				geofenceTransport={data.geofence}
				dataCreatePresetAlert={data}
				page="createPresetAlert"
			/>
			<div className="mt-10 flex items-center">
				<GeocodingStrackerGoogleMap
					address={data.fullAddress}
					id={index}
					getUpdateAddress={validAdresseTransit}
					height="40px"
					step={`step${index + 1}`}
					globalCode={globalCode}
					setGlobalCode={setGlobalCode}
					currentDialogPing={currentDialogPing}
					setCurrentDialogPing={setCurrentDialogPing}
					dataCreatePresetAlert={data}
					page="createPresetAlert"
				/>
				<button
					type="button"
					className="ml-10 mb-10"
					name={`step${index + 1}`}
					onClick={e => handleOpenDialogMapPing(e)}
				>
					<CrossHair className="pointer-events-none" size={20} />
				</button>
			</div>
		</div>
	));
}

export default GeoFencing;
