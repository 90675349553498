import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
	entities: [],
	alerts: {},
	total: 0,
	success: {},
	delete: {}
};

const alertsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_SC_ALERTS: {
			return {
				...state,
				success: {},
				alertsFav: action.payload
			};
		}
		case Actions.GET_SC_ALERTS_MYALERT: {
			return {
				...state,
				total: action.payload.total,
				alerts: _.toArray(action.payload, 'id')
			};
		}
		case Actions.CREATE_ALERT_SUCCESS: {
			return {
				...state,
				success: action.payload
			};
		}
		case Actions.UPDATE_ALERT_SUCCESS: {
			return {
				...state,
				success: action.payload
			};
		}
		case Actions.DELETE_ALERT_SUCCESS: {
			return {
				...state,
				delete: action.payload
			};
		}
		default:
			return state;
	}
};

export default alertsReducer;
