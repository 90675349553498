import React, { useState } from 'react';
import withReducer from 'app/store/withReducer';
import reducer from './store/reducers';
import AlertsHeader from './AlertsHeader';
import AlertsContent from './AlertsContent';
import AlertsAddAlert from './AlertsAddAlert';

function Alerts({ displayMenuBurger, setBurgerIsOpen, burgerIsOpen, handleCloseDialogSettings }) {
	const [alertsData, setAlertsData] = useState(null);
	const [displayAddAlert, setDisplayAddAlert] = useState(false);
	const [heightHeader, setHeightHeader] = useState(null);

	return (
		<>
			<AlertsHeader
				setHeightHeader={setHeightHeader}
				setDisplayAddAlert={setDisplayAddAlert}
				displayAddAlert={displayAddAlert}
				handleCloseDialogSettings={handleCloseDialogSettings}
				burgerIsOpen={burgerIsOpen}
				setBurgerIsOpen={setBurgerIsOpen}
				displayMenuBurger={displayMenuBurger}
			/>
			{displayAddAlert === false && heightHeader ? (
				<AlertsContent
					heightHeader={heightHeader}
					setDisplayAddAlert={setDisplayAddAlert}
					setAlertsData={setAlertsData}
				/>
			) : (
				''
			)}
			{displayAddAlert && heightHeader ? (
				<AlertsAddAlert
					heightHeader={heightHeader}
					setDisplayAddAlert={setDisplayAddAlert}
					alertsData={alertsData}
					setAlertsData={setAlertsData}
				/>
			) : (
				''
			)}
		</>
	);
}

export default withReducer('alerts', reducer)(Alerts);
