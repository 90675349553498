const locale = {
	ADD: 'Add',
	ADDRESS: 'Add an address',
	ADD_ASSET_REFERENCE: 'Add an asset reference',
	ADD_A_STEP: 'Add a step',
	STEP: 'Step',
	ADD_TRANSPORT: 'Shipment',
	AIRCRAFT: 'Air',
	ALERT: 'Alert',
	ALERTS: 'Alerts',
	ALREADY_USED: 'is already used',
	ARRIVAL: 'Arrival',
	ASSET: 'Asset',
	ASSETS: 'Assets',
	ASSET_INFO: 'Asset information',
	ASSOCIATE_TRANSPORT: 'Related shipment',
	BATEAU: 'Sea',
	BOUTON_CANCEL: 'Cancel',
	BOUTON_FINISHED: 'Save',
	BOUTON_MODIFY: 'Modify',
	BOUTON_RECYCLER: 'Recycle',
	BOUTON_SHARE: 'Copy link',
	BOUTON_STOCKER: 'Store',
	VALIDATE: 'Finish',
	CLOSE_TRANSPORT: 'Close shipment',
	COMING_SOON: 'Coming soon',
	CONTAINER_INFO: 'Information about the asset',
	COPIED_LINK: 'Link copied into clipboard.',
	CREATED: 'Created',
	COTATION: 'Quote',
	CREATE_TRANSPORT: 'Creating a shipment',
	CREATE_TRANSPORT_SUBTITLE1: 'Shipment reference',
	CREATE_TRANSPORT_SUBTITLE2: 'Link your shipment to one or several trackers',
	CREATE_TRANSPORT_SUBTITLE3: 'Asset reference',
	CREATE_TRANSPORT_SUBTITLE4: 'Define the route',
	CREATE_TRANSPORT_SUBTITLE5: 'Customize the alerts',
	CURRENT_ROUTE: 'Route',
	DEFAULT: 'Default',
	DELETE_ASSET_TEXT:
		'Are you sure to remove this asset? You can choose between recycling the related tracker in case of a single use or to move it to your stock in case it will be used later for a new transport',
	DELETE_ASSET_TRANSPORT: 'Delete asset',
	DELETE_A_STEP: 'Remove this step',
	DEPARTURE: 'Departure',
	DESTINATION: 'Destination',
	DESTINATION_END: 'Arrived',
	DIALOG_FINALISE: 'Save',
	DIALOG_NEXT: 'Next',
	DIALOG_PRECEDENT: 'Previous',
	DOWNLOAD_CSV: 'Export data',
	EDIT: 'Edit references',
	ERROR_ADD_TRANSPORT: 'Add a shipment reference',
	ERROR_MESSAGE_DIALOG3: 'is already used as an asset reference',
	ERROR_SELECT_TRACKER: 'Select at least one tracker',
	ERROR_TRANSPORT_NAME: 'This reference is already used for another shipment',
	ETAPE: 'Step',
	ETAPES: 'Steps',
	GEOFENCE: 'Area of the geofence',
	GEOFENCING: 'Geofencing',
	HUMIDITY: 'Humidity',
	HUMIDITY_HISTORY: 'Humidity history',
	INCORRECT_ADDRESS: 'Incorrect address',
	INFORMATION: 'Notes',
	LOADING_AVAILABLE_TRACKERS: 'Loading available trackers',
	MINI_CARACTERE: '3 characters minimum',
	MODIFY_ROUTE: 'Update the route',
	NOTIFICATIONS: 'Notification management',
	NO_TRACKER_AVAILABLE: 'No available tracker',
	POSITION: 'Position',
	RADIUS: 'Radius',
	READY_TO_GO: 'Ready to go',
	REFRESH: 'Refresh',
	DELETE_ASSET: 'Remove asset',
	RIDE: 'Route',
	SCANNER: 'Scan',
	SEARCH_FOR_TRACKER: 'Search for a tracker',
	SEARCH_FOR_TRANSPORT: 'Search for...',
	SEARCH: 'Search for',
	SELECT_AN_ADDRESS: 'Add an address',
	SHARE_INFORMATIONS: 'Share information',
	SHARE: 'Share',
	SHARED_LINK: 'Link to share',
	SHARE_CHOSE_OPTION: 'Select the historical and live data that you want to share',
	SHARE_GENERATE_LINK: 'Generate link',
	SHARE_TRACKER: 'Share asset information',
	SHARE_TRANSPORT: 'Share shipment information',
	SIMILAR_POSITION: '# similar positions',
	TEMPERATURE: 'Temperature',
	TEMPERATURE_HISTORY: 'Temperature history',
	TEMPERATURE_HUMIDITY: 'Sensors',
	TITLE: 'Example Page',
	TO_COMPLETE: 'In process',
	TRACKER_LIST: 'tracker(s) available',
	TRANSIT: 'In transit',
	TRANSPORT: 'shipment',
	TRANSPORTS: 'shipments',
	TRANSPORT_ADD_FILTER: 'Filters',
	TRANSPORT_ENTER_NAME: 'Add a shipment reference',
	TRANSPORT_EXPLAIN_TEXT_1: 'Define under which unique reference you want to identify this shipment',
	TRANSPORT_EXPLAIN_TEXT_2:
		'Select - through the list or the search bar - the serial number of the tracker to be linked to this shipment. Add as many trackers as assets you plan to track',
	TRANSPORT_EXPLAIN_TEXT_3: 'Define under which unique reference you want to identify each asset (optional)',
	TRANSPORT_EXPLAIN_TEXT_4:
		'Add the details of your shipment to be notified through the different steps of your route. Each departure, arrival and intermediate step zones are defined by an area within a certain radius around the selected address',
	TRANSPORT_EXPLAIN_TEXT_5:
		'Select the data that you want to monitor as well as the alerts for which you will be notified',
	TRANSPORT_HIDE_FILTERS: 'Hide',
	ARCHIVE: 'Archive',
	TRANSPORT_TYPE: 'Type of shipment',
	TRUCK: 'Road',
	ROUTE: 'Route',
	UPDATE_ON: 'Update on ',
	WRITE_ASSET_REFERENCE: 'Add an asset reference',
	DATE_PICKER_BEGIN: 'Begin',
	DATE_PICKER_END: 'End',
	ETA_DATE_PICKER_BEGIN: 'Departure',
	ETA_DATE_PICKER_END: 'Arrival',
	allAcces: 'Asset details',
	event: 'Events',
	humidity: 'Humidity',
	map: 'Geolocation',
	MAP: 'Map',
	temperature: 'Temperature',
	TITLE_INFO: 'Notes',
	CHAT: 'Chat',
	EVENT: 'Events',
	CUSTOM_EVENT: 'Informations',
	TYPE_EVENT: 'Type of events',
	ADD_CUSTOM_EVENT: 'Add an event',
	ETA: 'Estimated Time',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	MODIFICATION: 'Modification',
	IN_CHARGE: 'In charge',
	HISTORY_OF: 'History of',
	HISTORY_NONE: 'No history',
	FILTER: 'Filter',
	ENDSCROLL: 'No more events',
	READ_FILTER: 'Read',
	HIDE_FILTERS: 'Hide filters',
	UNREAD_FILTER: 'Unread',
	SHOW_FILTERS: 'Show filters',
	STATUS_FILTER: 'Status',
	LOADING: 'Loading',
	FILES: 'Shared files',
	SELECT: 'Select',
	RESUME: 'Resume',
	PURCHASES: 'Purchases',
	PURCHASES_INFO: 'Informations about the purchases',
	CREATE_PDF: 'Create PDF',
	DELIVERY_BILL: 'Delivery bill',
	GENERATE_DELIVERY_BILL: 'Generate delivery bill',
	DELETE_TRANSPORT: 'Delete transport',
	PICK_UP: 'Pick-up',
	CUSTOMS: 'Customs',
	HANDLING: 'Handling',
	FREIGHT: 'Freight',
	DELIVERY: 'Delivery',
	TAX: 'Tax',
	SERVICES: 'Services',
	SERVICE_PROVIDER: 'Service provider',
	OTHER: 'Others',
	TOTAL: 'Total',
	SHIPMENT_DETAILS: 'Shipment details',
	COST_REVENUE: 'Pricing',
	CARBON_FOOTPRINT: 'Carbon footprint',
	DETAILS: 'Details',
	EXPORT_DATA: 'Export Excel',
	EXPORT_YOUR_DATA: 'Export your data',
	WITHOUT_TAXES: 'Duty free',
	SALE: 'Sale',
	PURCHASES_TOTAL: 'Total purchases',
	SALES_TOTAL: 'Total sale',
	BUDGET: 'Budget',
	FLIGHT: 'Flight',
	NUMBER: 'Number',
	DATE: 'Date',
	AWB: 'AWB',
	PREFIX: 'Prefix',
	SUFFIX: 'Suffix',
	NAME: 'Name',
	PHONENUMBER: 'Phone number',
	DRIVER_INFORMATION: 'Driver information',
	DRIVER: 'Driver',
	PHONE_ABV: 'Phone',
	PLATE: 'Plate',
	ADD_AIRPORT: 'Add an Airport',
	NOT_SPECIFIED: 'Not specified',
	UNRECOGNIZED_AIRLINE: 'Unrecognized airline',
	UNITS: 'units',
	UNIT: 'unit',
	FROM_FILTER: 'from ',
	TO_FILTER: 'to ',
	CLIENTS: 'Clients',
	CLIENT: 'Client',
	CAR: 'Car',
	PLANE: 'Plane',
	FILTER_BY: 'Filter by :',
	ORDER_BY: 'Order by :',
	VALIDATION_DATE: 'Validation date',
	TRANSPORT_REF: 'Transport reference',
	STRACKER_REF: 'Stracker reference',
	TAG: 'Tag',
	COMMODITY_NATURE: 'Comodity nature',
	DEPARTURE_ADDRESS: 'Departure address',
	DEPARTURE_CITY: 'Departure city',
	DEPARTURE_COUNTRY: 'Departure country',
	STOPOVER: 'Stopover',
	NB_STOPOVER: 'Stopover number',
	ARRIVAL_ADDRESS: 'Arrival address',
	ARRIVAL_CITY: 'Arrival city',
	ARRIVAL_COUNTRY: 'Arrival country',
	UNITS_TYPE: 'Units type',
	UNITS_NB: 'Units number',
	UNIT_GROSS_WEIGHT: 'Unit gross weight',
	UNIT_CHARGEABLE_WEIGHT: 'Unit chargeable weight',
	TOTAL_GROSS_WEIGHT: 'Total gross weight',
	TOTAL_CHARGEABLE_WEIGHT: 'Total chargeable weight',
	TRANSPORT_STATUS: 'Transport status',
	TRANSIT_TIME: 'Transit time',
	QUOTE: 'Quote',
	SELL: 'Sell',
	PURCHASE: 'Purchase',
	MARGIN: 'Margin'
};

export default locale;
