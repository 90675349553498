const locale = {
	BTN_READ_ALL_NOTIFICATIONS_TEXT: 'Marquer tout comme lu',
	BTN_READ_NOTIFICATION_TEXT: 'Marquer comme lu',
	PLACEHOLDER_SEARCH: 'Rechercher',
	TITLE: 'Notification',
	DIALOG_NOTIFICATION: "Paramétrer l'envoi des notifications relatives à l'ensemble des alertes du compte client",
	UNREAD_FILTER: 'Non lus',
	READ_FILTER: 'Lus',
	SHOW_FILTERS: 'Filtrer',
	STATUS_FILTER: 'Statut',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	HIDE_FILTERS: 'Masquer les filtres'
};

export default locale;
