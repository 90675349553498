/* eslint-disable default-case */
import API from '@pi/api';
//import _ from '@lodash';

export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';

export const UPDATE_ROLE_MEMBER_SUCCESS = 'UPDATE_ROLE_MEMBER_SUCCESS';
export const UPDATE_ROLE_MEMBER_ERROR = 'UPDATE_ROLE_MEMBER_ERROR';

export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';

export const CREATE_MULTI_MEMBER_ERROR = 'CREATE_MULTI_MEMBER_ERROR';
export const CREATE_MULTI_MEMBER_SUCCESS = 'CREATE_MULTI_MEMBER_SUCCESS';

export const GET_SC_MEMBERS = '[SUPPLY CHAIN MEMBERS] GET MEMBERS';
export const GET_SC_MEMBER = '[SUPPLY CHAIN MEMBERS MEMBER] GET MEMBERS MEMBER';
export const GET_SC_UPDATEMEMBER = '[SUPPLY CHAIN MEMBERS UPDATE] GET MEMBERS UPDATE';
export const SET_SEARCH_TEXT = '[SUPPLY CHAIN MEMBERS] SET SEARCH TEXT';

export function setSearchText(event) {
	return {
		type: SET_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function resetSearchText() {
	return {
		type: SET_SEARCH_TEXT,
		searchText: ''
	};
}

export function getMembres(membersList) {
	const request = API.post('/customer', {
		action: 'users',
		idCustomer: membersList.idCustomer,
		position: membersList.position,
		limit: membersList.limit
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_MEMBERS,
				payload: response.data
			})
		);
}

export function getMember(idUser) {
	const request = API.post('/user', {
		action: 'read',
		idUser
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_MEMBER,
				payload: response.data
			})
		);
}

export function updateMember(model) {
	const { idUser, email, firstName, lastName, phone, idCustomer, idConnectedUser } = model;

	const request = API.post('/user', {
		action: 'update',
		idUser,
		email,
		firstName,
		lastName,
		phone,
		idConnectedUser,
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: UPDATE_MEMBER_SUCCESS,
				payload: response.data
			})
		).then(() => {
			let membersList = {
				idCustomer,
				position: 1,
				limit: 300
			};
			dispatch(getMembres(membersList));
		});
}

export function updateRoleMember(model) {
	const { idUser, idRole, idCustomer, idConnectedUser} = model;
	const request = API.post('/user', {
		action: 'update',
		idUser,
		idRole,
		idConnectedUser,
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: UPDATE_ROLE_MEMBER_SUCCESS,
					payload: response.data
				})
			]).then(() => {
				let membersList = {
					idCustomer,
					position: 1,
					limit: 300
				};
				dispatch(getMembres(membersList));
			});
		});
}

export function deleteUser(memberInfo) { 
	const { idUser, idCustomer } = memberInfo;

	const request = API.post('/user', {
		action: 'delete',
		idUser
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: DELETE_MEMBER_SUCCESS,
					payload: response.data
				})
			]).then(() => {
				let membersList = {
					idCustomer,
					position: 1,
					limit: 300
				};
				dispatch(getMembres(membersList));
			});
		});
}

export function createMultiUser(usersList, userId, customerId) {
	const request = API.post('/user', {
		action: 'create',
		userId,
		customerId,
		usersList
	});
 
	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: CREATE_MULTI_MEMBER_SUCCESS,
					payload: response.data
				})
			])/*.then(() => {
				let membersList = {
					idCustomer:customerId,
					position: 1,
					limit: 300
				};
				dispatch(getMembres(membersList));

		});*/
	});
}

