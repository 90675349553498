import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import TrackNavHorizontalCollapse from './horizontal/TrackNavHorizontalCollapse';
import TrackNavHorizontalGroup from './horizontal/TrackNavHorizontalGroup';
import TrackNavHorizontalItem from './horizontal/TrackNavHorizontalItem';
import TrackNavHorizontalLink from './horizontal/TrackNavHorizontalLink';
import TrackNavVerticalCollapse from './vertical/TrackNavVerticalCollapse';
import TrackNavVerticalGroup from './vertical/TrackNavVerticalGroup';
import TrackNavVerticalItem from './vertical/TrackNavVerticalItem';
import TrackNavVerticalLink from './vertical/TrackNavVerticalLink';
import TrackNavItem, { registerComponent } from './TrackNavItem';
import './navigation.css';

/* *** Register Navigation Components *** */
registerComponent('vertical-group', TrackNavVerticalGroup);
registerComponent('vertical-collapse', TrackNavVerticalCollapse);
registerComponent('vertical-item', TrackNavVerticalItem);
registerComponent('vertical-link', TrackNavVerticalLink);
registerComponent('horizontal-group', TrackNavHorizontalGroup);
registerComponent('horizontal-collapse', TrackNavHorizontalCollapse);
registerComponent('horizontal-item', TrackNavHorizontalItem);
registerComponent('horizontal-link', TrackNavHorizontalLink);
registerComponent('vertical-divider', () => <Divider className="my-16" />);
registerComponent('horizontal-divider', () => <Divider className="my-16" />);

const useStyles = makeStyles(theme => ({
	navigation: {
		fontSize: 16,
		fontWeight: 600,
		'& .list-item': {
			'&:hover': {
				borderRadius: '8px',
				boxShadow:
					theme.palette.type === 'dark'
						? '0px 4px 4px rgb(7 78 232 / 15%)'
						: '0px 0px 0px rgb(7 78 232 / 15%)',
				color: `${theme.palette.primary.contrastText}`,
				backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : 'rgba(0,0,0,.04)',
				// backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)'
				'&:hover::after': {
					content: ''
				}
			},
			'&:focus:not(.active)': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)'
			},
			borderRadius: '6px',
			width: '85%',
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		'& .list-link': {
			'&:hover': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)'
			},
			'&:focus:not(.active)': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)'
			},
			borderRadius: '6px',
			width: '85%',
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	verticalNavigation: {
		'&.active-square-list': {
			'& .list-item, & .active.list-item': {
				width: '100%',
				borderRadius: '0'
			}
		},
		'&.dense': {
			'& .list-item': {
				paddingTop: 0,
				paddingBottom: 0,
				height: 32
			}
		}
	},
	horizontalNavigation: {
		'&.active-square-list': {
			'& .list-item': {
				borderRadius: '0'
			}
		},
		'& .list-item': {
			padding: '8px 12px 8px 12px',
			height: 40,
			minHeight: 40,
			'&.level-0': {
				height: 44,
				minHeight: 44
			},
			'& .list-item-text': {
				padding: '0 0 0 8px'
			}
		},
		'& .list-link': {
			padding: '8px 12px 8px 12px',
			height: 40,
			minHeight: 40,
			'&.level-0': {
				height: 44,
				minHeight: 44
			},
			'& .list-item-text': {
				padding: '0 0 0 8px'
			}
		}
	},
	'@global': {
		'.popper-navigation-list': {
			'& .list-item': {
				padding: '8px 12pxz',
				height: 40,
				minHeight: 40,
				'& .list-item-text': {
					padding: '0 0 0 8px'
				}
			},
			'&.dense': {
				'& .list-item': {
					minHeight: 32,
					height: 32,
					'& .list-item-text': {
						padding: '0 0 0 8px'
					}
				}
			}
		}
	}
}));

function TrackNavigation(props) {
	const classes = useStyles(props);
	const { navigation, layout, active, dense, className } = props;

	const user = useSelector(({ auth }) => auth.user);

	const verticalNav = (
		<List
			className={clsx(
				'navigation whitespace-nowrap',
				classes.navigation,
				classes.verticalNavigation,
				`active-${active}-list`,
				dense && 'dense',
				className
			)}
		>
			{navigation.map(_item => {
				// eslint-disable-next-line
				if (_item.id === 'fashionhub' && user.data.vertical !== 'LUXE' && user.role !== 'superAdmin') return;
				return <TrackNavItem key={_item.id} type={`vertical-${_item.type}`} item={_item} nestedLevel={0} />;
			})}
		</List>
	);

	const horizontalNav = (
		<List
			className={clsx(
				'navigation whitespace-nowrap flex p-0',
				classes.navigation,
				classes.horizontalNavigation,
				`active-${active}-list`,
				dense && 'dense',
				className
			)}
		>
			{navigation.map(_item => (
				<TrackNavItem
					key={_item.id}
					type={`horizontal-${_item.type}`}
					item={_item}
					nestedLevel={0}
					dense={dense}
				/>
			))}
		</List>
	);

	if (navigation.length > 0) {
		switch (layout) {
			case 'horizontal': {
				return horizontalNav;
			}
			case 'vertical':
			default: {
				return verticalNav;
			}
		}
	} else {
		return null;
	}
}

TrackNavigation.propTypes = {
	navigation: PropTypes.array.isRequired
};

TrackNavigation.defaultProps = {
	layout: 'vertical'
};

export default React.memo(TrackNavigation);
