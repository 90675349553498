import React, { useState, useRef, useEffect, useCallback } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const GeocodingStrackerGoogleMap = React.memo(
	({
		getUpdateAddress,
		width,
		height,
		step,
		page,
		id,
		contentDiv,
		globalCode,
		currentDialogPing,
		setCurrentDialogPing,
		address,
		setGlobalCode,
		type,
		dataCreatePresetAlert
	}) => {
		let transportDetails = null;
		let deviceDetails = null;
		if (page === 'transport')
			transportDetails = useSelector(({ transportApp }) => transportApp.transport.transportDetails);
		else if (page === 'asset') deviceDetails = useSelector(({ assetsApp }) => assetsApp.details.deviceDetails);
		const [addressValue, setAddressValue] = useState(address || '');
		const { t } = useTranslation('transport');
		const validAddress = {};

		const useStyles = makeStyles(theme => ({
			font1: { color: theme.palette.stracker.contrastTextDark, fontWeight: '600' },
			font2: { color: theme.palette.stracker.contrastTextLight, fontWeight: '600' },
			IconBleu: theme.palette.stracker.contrastTextDark,
			backgroundSearchBar: {
				fontFamily: 'Open Sans',
				background: theme.palette.stracker.body,
				width: width || '100%',
				lineHeight: '18px',
				fontSize: '14px',
				padding: '5px 0px 5px 15px',
				borderRadius: '8px',
				outlineStyle: 'none',
				border: '2px solid unset',
				height
			},
			input: {
				color: 'red'
			}
		}));

		const classes = useStyles();
		const blueBackground = useTheme().palette.stracker.input;
		const fontColorDark = useTheme().palette.stracker.contrastTextDark;
		const ColorBody = useTheme().palette.stracker.card;

		const handleSelect = async value => {
			if (setCurrentDialogPing) {
				setCurrentDialogPing(null);
			}

			const results = await geocodeByAddress(value);
			const latLng = await getLatLng(results[0]);

			setAddressValue(value);

			const addressReq = results[0].address_components.reduce((accu, value) => {
				if (value.types[0] === 'street_number') accu.number = value.long_name;
				if (value.types[0] === 'route') {
					if (accu.number) {
						accu.street = `${accu.number} ${value.long_name}`;
						delete accu.number;
					} else {
						accu.street = value.long_name;
					}
				}
				if (value.types[0] === 'locality' || value.types[0] === 'postal_town') accu.city = value.long_name;
				if (value.types[0] === 'country') accu.country = value.long_name;
				if (value.types[0] === 'postal_code') {
					accu.postCode = value.long_name;
				}

				return accu;
			}, {});

			if (step === 'start') validAddress.fullAddressStart = results[0].formatted_address;
			if (step.includes('step') || step === 'transit') validAddress.fullAddress = results[0].formatted_address;
			if (step === 'end') validAddress.fullAddressEnd = results[0].formatted_address;

			validAddress.address = addressReq;
			validAddress.longitude = latLng.lng;
			validAddress.latitude = latLng.lat;
			validAddress.step = step.includes('step') ? 'transit' : step;

			if (globalCode && step.includes('step')) {
				const copyGlobalCodeTransit = globalCode.transit;
				if (copyGlobalCodeTransit[id]) {
					copyGlobalCodeTransit[id].code = null;
					copyGlobalCodeTransit[id].coordinate.lat = latLng.lat;
					copyGlobalCodeTransit[id].coordinate.lng = latLng.lng;
					copyGlobalCodeTransit[id].formattedAddress = results[0].formatted_address;
				}

				if (!type && page === 'transport') {
					transportDetails.trip[id].fullAddress = results[0].formatted_address;
				} else if (!type && page === 'asset') {
					deviceDetails.area[id].fullAddress = results[0].formatted_address;
				} else if (!type && page === 'createPresetAlert') {
					dataCreatePresetAlert.fullAddress = results[0].formatted_address;
				}

				setGlobalCode({
					...globalCode,
					transit: copyGlobalCodeTransit
				});
			} else if (globalCode) {
				setGlobalCode({
					...globalCode,
					[step]: {
						coordinate: {
							lat: latLng.lat,
							lng: latLng.lng
						},
						code: null,
						formattedAddress: results[0].formatted_address
					}
				});
			}

			getUpdateAddress && getUpdateAddress(validAddress, id);
		};

		const handleChange = ev => {
			setAddressValue(ev);

			if (page === 'transport') {
				if (step === 'start') transportDetails.addressStart.fullAddressStart = ev;
				if (step.includes('step') || step === 'transit') transportDetails.trip[id].fullAddress = ev;
				if (step === 'end') transportDetails.addressDestination.fullAddressEnd = ev;
			}
			if (page === 'asset') deviceDetails.area[id].fullAddress = ev;
			if (page === 'createPresetAlert') dataCreatePresetAlert.fullAddress = ev;

			if (divSelection.current)
				divSelection.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			setTimeout(() => {
				if (divSelection.current)
					divSelection.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}, 1000);
		};

		const divSelection = useRef(null);

		const searchOptions = {
			language: 'en'
		};

		const refresh = useCallback(() => {
			if (currentDialogPing && step === currentDialogPing) {
				if (currentDialogPing.includes('step')) {
					const stepNumber = currentDialogPing.replace('step', '');
					const parseStepNumber = parseInt(stepNumber, 10);
					if (page === 'transport') handleChange(globalCode.transit[parseStepNumber - 1].formattedAddress);
					if (page === 'asset') handleChange(globalCode.transit[parseStepNumber - 1].formattedAddress);
					if (type === 'create') handleChange(globalCode.transit[parseStepNumber - 1].formattedAddress);
				} else if (globalCode[currentDialogPing].code) {
					handleChange(globalCode[currentDialogPing].formattedAddress);
				}
			}
			// eslint-disable-next-line
		}, [globalCode]);

		useEffect(() => {
			if (address) setAddressValue(address);
			if (globalCode) {
				refresh();
			}
			// eslint-disable-next-line
		}, [globalCode && globalCode, address]);

		return (
			<PlacesAutocomplete
				value={
					page === 'transport'
						? transportDetails &&
						  transportDetails.trip &&
						  transportDetails.trip[id] &&
						  transportDetails.trip[id].fullAddress
							? transportDetails?.trip[id]?.fullAddress
							: addressValue
						: page === 'asset'
						? deviceDetails &&
						  deviceDetails.area &&
						  deviceDetails.area[id] &&
						  deviceDetails.area[id].fullAddress
							? deviceDetails?.area[id]?.fullAddress
							: addressValue
						: page === 'createPresetAlert'
						? dataCreatePresetAlert && dataCreatePresetAlert.fullAddress
							? dataCreatePresetAlert?.fullAddress
							: addressValue
						: addressValue
				}
				onChange={handleChange}
				onSelect={handleSelect}
				searchOptions={searchOptions}
				debounce={800}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
					return (
						<div className="w-full">
							<input
								{...getInputProps({ placeholder: t('ADDRESS') })}
								className={clsx(classes.font1, classes.backgroundSearchBar)}
								ref={contentDiv}
							/>

							<div className="mt-5 rounded">
								{loading ? <div>...loading</div> : null}

								{suggestions.map((suggestion, index) => {
									const style = {
										background: suggestion.active ? blueBackground : ColorBody,
										width: width || '400px',
										padding: '5px 15px 2px',
										color: fontColorDark,
										fontWeight: '600',
										display: 'flex',
										justifyContent: 'space-between'
									};

									return (
										<div
											{...getSuggestionItemProps(suggestion, { style })}
											key={index}
											ref={divSelection}
										>
											<p className="cursor-pointer">{suggestion.description}</p>
										</div>
									);
								})}
							</div>
						</div>
					);
				}}
			</PlacesAutocomplete>
		);
	}
);

export default GeocodingStrackerGoogleMap;
