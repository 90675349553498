import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import * as Actions from './store/actions';
import Input from '@material-ui/core/Input';
import { Paper } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';

const useStyles = makeStyles(theme => ({
	MboutonBoxV: {
		borderRadius: '8px',
		minWidth: '100px',
		height: '34px'
	},
	hoverColor: {
		backgroundColor: '#fff'
	},
	hoverValidButton: {
		backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
	},
	hoverPhoneButton: {
		backgroundColor: theme.palette.stracker.contrastInput
	},
	'@global': {
		'.mbottomButtom': {
			width: '220px',
			textAlign: 'right',
			marginLeft: 'auto'
		},
		'.hoverColor:hover': {
			backgroundColor: theme.palette.stracker.contrastText, // '#074EE8',
			color: '#fff !important'
		},
		'hoverValidButton:hover': {
			backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
		},
		'.mbottomButtom .hoverValidButton:hover': {
			backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
		},
		'.mbottomButtom .bcancel': {
			backgroundColor: 'transparent',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			border: `1px solid ${theme.palette.secondary.colorBorderSelect}` // #A8C1F7'
		},
		'.hoverPhoneButton:hover': {
			backgroundColor: theme.palette.stracker.contrastInput
		}
	}
}));

const ContractorsForm = ({ setPageRouter, pageRouter, selectedContractor, filteredData, setFilteredData }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('contractors');

	const user = useSelector(({ auth }) => auth.user);
	const contractorsList = useSelector(({ members }) => members.contractor.state);

	const { idCustomer } = user.data;

	const [formList, setFormList] = useState({ identity: '', email: '', phone: '', occupation: '' });

	// Permet de récupérer le curent contractor
	useEffect(() => {
            if (user.data.idCustomer) {
                dispatch(Actions.getContractors(user.data.idCustomer));
            }
			// eslint-disable-next-line
	}, [user, selectedContractor && selectedContractor.idContractor]);

	useEffect(() => {
		function getFilteredArray() {
			const arr = Object.keys(contractorsList).map(id => contractorsList[id]);
			return arr;
		}

		if (contractorsList) {
			setFilteredData(getFilteredArray());
		}
		// eslint-disable-next-line
	}, [contractorsList]);

	// va définir les valeurs de défault de l'input si on est dans le cas d'une modification
	useEffect(() => {
		let list = {};
		list.identity = selectedContractor ? selectedContractor.identity : '';
		list.email = selectedContractor ? selectedContractor.email : '';
		list.phone = selectedContractor ? selectedContractor.phone : '';
		list.occupation = selectedContractor ? selectedContractor.occupation : '';
		setFormList(list);
		// eslint-disable-next-line
	}, []);

	// Va changer la valeur du form à chaque changement
	const handleInputChange = e => {
		const { name, value } = e.target;
		const list = { ...formList };
		list[name] = value;
		setFormList(list);
	};

	// fonction spécifique pour l'input phone qui est diff des autres
	const handleChangePhone = e => {
		const list = { ...formList };
		list.phone = e;
		setFormList(list);
	};

	// Va exécuter l'action au moment de la soumission du forme
	function handleSubmit(event) {
		event.preventDefault();
		if (pageRouter === 'create') {
			dispatch(
				Actions.createContractor(
					formList.identity,
					formList.email,
					formList.phone,
					formList.occupation,
					idCustomer
				)
			).then(() => {
                dispatch(Actions.getContractors(user.data.idCustomer));
            })
		} else {
			let filteredDataCpy = filteredData;
			filteredData.forEach((data, i) => {
				if (selectedContractor.idContractor === filteredDataCpy[i].idContractor) {
					filteredDataCpy[i].identity = formList.identity;
					filteredDataCpy[i].email = formList.email;
					filteredDataCpy[i].phone = formList.phone;
					filteredDataCpy[i].occupation = formList.occupation;
				}
			});

			dispatch(
				Actions.updateContractor(
					formList.identity,
					formList.email,
					formList.phone,
					formList.occupation,
					idCustomer,
					selectedContractor.idContractor
				)
			);

			setFilteredData(filteredDataCpy);
		}
		setPageRouter('');
	}

	return (
		<>
			<div className="flex flex-col">
				<div>
					<PaperStyled
						className={clsx('flex items-center px-12 py-5 w-full rounded-lg sm:w-auto mt-16')}
						style={{ backgroundColor: theme.palette.stracker.contrastInput }}
						elevation={0}
					>
						<Input
							style={{ width: '100%', color: theme.palette.stracker.contrastTextDark, fontWeight: 600 }}
							placeholder={t('IDENTITY')}
							disableUnderline
							name="identity"
							onChange={e => handleInputChange(e)}
							defaultValue={
								selectedContractor && selectedContractor.identity ? selectedContractor.identity : ''
							}
						/>
					</PaperStyled>
					<PaperStyled
						className={clsx('flex items-center px-12 py-5 w-full rounded-lg sm:w-auto mt-16')}
						style={{ backgroundColor: theme.palette.stracker.contrastInput }}
						elevation={0}
					>
						<Input
							style={{ width: '100%', color: theme.palette.stracker.contrastTextDark, fontWeight: 600 }}
							placeholder={t('EMAIL')}
							disableUnderline
							name="email"
							onChange={e => handleInputChange(e)}
							defaultValue={
								selectedContractor && selectedContractor.email ? selectedContractor.email : ''
							}
						/>
					</PaperStyled>

					<PhoneInput
						country={'fr'}
						className={clsx('flex items-center  rounded-lg sm:w-auto mt-16')}
						name="phone"
						containerStyle={{ width: '100%' }}
						inputStyle={{
							backgroundColor: theme.palette.stracker.contrastInput,
							width: '100%',
							border: 'none',
							color: theme.palette.stracker.contrastTextDark,
							fontWeight: 600
						}}
						buttonClass={clsx(classes.hoverPhoneButton)}
						buttonStyle={{
							backgroundColor: theme.palette.stracker.contrastInput,
							border: 'none',
							borderRight: '1px solid lightgrey'
						}}
						placeholder={t('PHONE')}
						onChange={event => handleChangePhone(event)}
						value={selectedContractor && selectedContractor.phone ? selectedContractor.phone : ''}
					/>
					<PaperStyled
						className={clsx('flex items-center px-12 py-5 w-full rounded-lg sm:w-auto mt-16')}
						style={{ backgroundColor: theme.palette.stracker.contrastInput }}
						elevation={0}
					>
						<Input
							style={{
								width: '100%',
								color: theme.palette.stracker.contrastTextDark,
								fontWeight: 600
							}}
							placeholder={t('ROLE')}
							disableUnderline
							name="occupation"
							onChange={e => handleInputChange(e)}
							defaultValue={
								selectedContractor && selectedContractor.occupation ? selectedContractor.occupation : ''
							}
						/>
					</PaperStyled>
				</div>
				<div className="bottom-14 mbottomButtom" style={{ position: 'absolute', right: '5%', bottom: '15px' }}>
					<Button
						variant="contained"
						color="primary"
						className={clsx(
							classes.MboutonBoxV,
							'bcancel w-content mx-auto normal-case shadow-none text-white float-left'
						)}
						aria-label="CANCEL"
						type="cancel"
						onClick={() => setPageRouter('')}
					>
						{t('CANCEL')}
					</Button>

					{pageRouter === 'create' ? (
						<Button
							variant="contained"
							color="primary"
							disabled={formList.identity === ''}
							className={clsx(
								classes.MboutonBoxV,
								'w-content mx-auto normal-case shadow-none text-white'
							)}
							style={{
								background: formList.identity === '' ? '#EEEEEE' : theme.palette.stracker.contrastText
							}}
							aria-label="CREATE"
							type="submit"
							onClick={handleSubmit}
						>
							{t('CREATE')}
						</Button>
					) : (
						<Button
							variant="contained"
							color="primary"
							disabled={formList.identity === ''}
							className={clsx(
								classes.MboutonBoxV,
								'w-content mx-auto normal-case shadow-none text-white'
							)}
							aria-label="UPDATE"
							style={{
								background: formList.identity === '' ? '#EEEEEE' : theme.palette.stracker.contrastText
							}}
							type="update"
							onClick={handleSubmit}
						>
							{t('UPDATE')}
						</Button>
					)}
				</div>
			</div>
		</>
	);
};

const PaperStyled = styled(Paper)`
	.MuiInputBase-input {
		padding: 7px 0;
		color: ${props => props.theme.palette.stracker.contrastTextDark};
	}
	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: red;
		opacity: 1; /* Firefox */
	}
`;

export default ContractorsForm;
